import { useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import { useFormDataContext } from "../../../providers/form";
import { ENDPOINTS } from "../../../constants/endpoints";
import { useUsersDataContext } from "../providers/users";
import { useToast } from "../../app/components/ToastContext";

function useCreateEditUser(userId = null, resetForm) {
    const userData = useUsersDataContext();
    const formData = useFormDataContext();
    const { fetch, loading, validations, handleValidations } = useFetch();
    const {successToast} = useToast()

    async function handleSave(data) {
        let auth = localStorage.getItem('site');
        const token = auth;
        if (loading) return;
        handleValidations().clear();

        const endpoint = userId ? ENDPOINTS.USER.UPDATE : ENDPOINTS.USER.CREATE;
        const method = "post";

        try {
            const response = await fetch({
                endpoint,
                method,
                data,
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                userData.refetchData(userData.getCurrentPage());
                resetForm();
                successToast('Успешно запазихте промените')
            } else {
                console.error('Request failed with status:', response.status);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    return {
        loading,
        handleSave,
        validations,
        handleValidations
    };
}

export default useCreateEditUser;
