import moment from "moment"
import { useEffect, useState } from "react";
import { Button, Col, Container, Image, Nav, Pagination, Row, Table } from "react-bootstrap"
import Moment from "react-moment"
import liveImage from "../../../assets/images/app/live.png"
import Session from "./Session";
import { useSessionsDataContext } from "../providers/sessions";
import addImage from "../../../assets/images/app/add.svg"
import { usePastSessionsDataContext } from "../providers/pastSessions";

function PastSessions({ data }) {
    const sessions = usePastSessionsDataContext()
    const { getUpcomingSessions, getCurrentPagePast, refetchDataPast, setPagePast, loading } = sessions

    return (
        <section>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th style={{ width: '10%' }}>
                            Дата на заседанието
                        </th>
                        <th style={{ width: '20%' }}>
                            Име
                        </th>
                        <th style={{ width: '25%' }}>
                            Комисия
                        </th>
                        <th style={{ width: '10%' }}>
                            Материали за заседанието
                        </th>
                        <th style={{ width: '10%' }}>
                            Протоколи за заседанието
                        </th>
                        <th style={{ width: '10%' }}>
                            Създадено на
                        </th>
                        <th style={{ width: '15%' }}>
                            Опции
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((s) => (
                        <Session page={getCurrentPagePast()} tab={'past'} key={s.id} data={s} />
                    ))}
                </tbody>
            </Table>
        </section>
    )
}
export default PastSessions