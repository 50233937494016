import { useState } from 'react';
import { Alert, CloseButton, Image, Nav, Row, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';  // Import a custom SortableItem component to handle individual sortable rows
import Delete from './Delete';
import documentImage from "../../../assets/images/app/pdfIcon.png";
import downloadImage from "../../../assets/images/app/download-icon.svg";
import removeImage from "../../../assets/images/app/remove.svg";
import { ENDPOINTS } from '../../../constants/endpoints';
import useOrderFiles from '../hooks/useOrderFiles';

function Documents(props) {
    const sessionId = props.data?.id;
    const {handleSave} = useOrderFiles();
    const [protocolFiles, setProtocolFiles] = useState(props.data?.protocols || []);
    const [materialFiles, setMaterialFiles] = useState(props.data?.materials || []);
    const [modalShow, setModalShow] = useState(false);
    const [docData, setDocData] = useState({
        id: 0,
        type: props.type || "",
    });

    function deleteDoc(docId, docType) {
        setDocData({
            id: docId,
            type: docType,
        });
        setModalShow(true);
    }

    // Function to handle drag end for protocols
    function handleProtocolDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = protocolFiles.findIndex((file) => file.protocol.id === active.id);
            const newIndex = protocolFiles.findIndex((file) => file.protocol.id === over.id);
            const newFiles = arrayMove(protocolFiles, oldIndex, newIndex);
            setProtocolFiles(newFiles);

            // Send the updated order to the server
            updateFileOrder(newFiles, 'protocols');
        }
    }

    // Function to handle drag end for materials
    function handleMaterialDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = materialFiles.findIndex((file) => file.material.id === active.id);
            const newIndex = materialFiles.findIndex((file) => file.material.id === over.id);
            const newFiles = arrayMove(materialFiles, oldIndex, newIndex);
            setMaterialFiles(newFiles);

            // Send the updated order to the server
            updateFileOrder(newFiles, 'materials');
        }
    }

    // Function to send the new order to the backend
    function updateFileOrder(newFiles, fileType) {
        const orderedFileIds = newFiles.map((file) => file[fileType.slice(0, -1)].id);

        handleSave({ sessionId, orderedFileIds, fileType })
    }

    function renderProtocols() {
        return (
            protocolFiles.length > 0 ? (
                <DndContext collisionDetection={closestCenter} onDragEnd={handleProtocolDragEnd}>
                    <SortableContext items={protocolFiles.map((file) => file.protocol.id)} strategy={verticalListSortingStrategy}>
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '85%' }}>Име</th>
                                    <th style={{ width: '15%' }}>Опции</th>
                                </tr>
                            </thead>
                            <tbody>
                                {protocolFiles.map((p) => (
                                    <SortableItem key={p.protocol.id} id={p.protocol.id}>
                                        <td>
                                            <Image src={documentImage} className="material-image" />
                                            <span style={{ textAlign: 'center', paddingLeft: '15px' }}>{p.protocol.name}</span>
                                        </td>
                                        <td>
                                            <Nav>
                                                <Nav.Link target='_blank' href={p.protocol.path}>
                                                    <Image style={{ height: '70%', marginRight: '-10px' }} src={downloadImage} />
                                                </Nav.Link>
                                                <Nav.Link onClick={() => deleteDoc(p.protocol.id, 'protocols')}>
                                                    <Image style={{ height: '70%', marginRight: '-10px' }} src={removeImage} />
                                                </Nav.Link>
                                            </Nav>
                                        </td>
                                    </SortableItem>
                                ))}
                            </tbody>
                        </Table>
                    </SortableContext>
                </DndContext>
            ) : (
                <Alert variant="warning">
                    <Alert.Heading>Внимание!</Alert.Heading>
                    <p>Към това заседание няма прикачени протоколи!</p>
                </Alert>
            )
        );
    }

    function renderMaterials() {
        return (
            materialFiles.length > 0 ? (
                <DndContext collisionDetection={closestCenter} onDragEnd={handleMaterialDragEnd}>
                    <SortableContext items={materialFiles.map((file) => file.material.id)} strategy={verticalListSortingStrategy}>
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '85%' }}>Име</th>
                                    <th style={{ width: '15%' }}>Опции</th>
                                </tr>
                            </thead>
                            <tbody>
                                {materialFiles.map((m) => (
                                    <SortableItem key={m.material.id} id={m.material.id}>
                                        <td>
                                            <Image src={documentImage} className="material-image" />
                                            <span style={{ textAlign: 'center', paddingLeft: '15px' }}>{m.material.name}</span>
                                        </td>
                                        <td>
                                            <Nav>
                                                <Nav.Link target='_blank' href={m.material.path}>
                                                    <Image style={{ height: '70%', marginRight: '-10px' }} src={downloadImage} />
                                                </Nav.Link>
                                                <Nav.Link onClick={() => deleteDoc(m.material.id, 'materials')}>
                                                    <Image style={{ height: '70%', marginRight: '-10px' }} src={removeImage} />
                                                </Nav.Link>
                                            </Nav>
                                        </td>
                                    </SortableItem>
                                ))}
                            </tbody>
                        </Table>
                    </SortableContext>
                </DndContext>
            ) : (
                <Alert variant="warning">
                    <Alert.Heading>Внимание!</Alert.Heading>
                    <p>Към това заседание няма прикачени материали!</p>
                </Alert>
            )
        );
    }

    return (
        <section>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.data?.name ?? props.data?.event_date} - {props.type === 'protocols' ? 'Протоколи' : 'Материали'}
                    </Modal.Title>
                    <CloseButton onClick={props.onHide} variant='white' />
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">
                        {props.type === 'protocols' ? renderProtocols() : renderMaterials()}
                    </Row>
                </Modal.Body>
            </Modal>
            <Delete
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={docData}
                tab={props.tab}
                type={props.type}
            />
        </section>
    );
}

export default Documents;
