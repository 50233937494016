import { useEffect, useState } from "react"
import { ENDPOINTS } from "../../../constants/endpoints"
import useFetch from "../../../hooks/useFetch"

function useFetchUnread() {
    const {fetch,loading} = useFetch()

    const [councilors,setCouncilors] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [unreadCount, setUnreadCount] = useState(0) //Да се вземе count полето от респонса на заявката
    useEffect(() => {
        _fetchData(currentPage)
    }, [currentPage])
    
    function _fetchData() {
        fetch({endpoint: `${ENDPOINTS.COUNCILOR.GET_UNREAD}?page=${currentPage}`,method:'get'})
        .then(res => {
            setCouncilors(res.data)
            setUnreadCount(res.data.count)
        })
    }

    function getUnreadCouncilors() {
        return councilors
    }

    function getCurrentPageUnread() {
        return currentPage
    }

    function getUnreadTabTitle()
    {
        if(unreadCount > 0) return 'Съветници с непрочетени съобщения '+'('+unreadCount+')'
        else return 'Съветници с непрочетени съобщения'
    }

    function setPageUnread(page) {
        setCurrentPage(page)
    }

    function refetchUnreadData(page) {
        _fetchData(page)
    }

    return {
        getUnreadCouncilors,
        refetchUnreadData,
        getCurrentPageUnread,
        setPageUnread,
        getUnreadTabTitle,
        loading
    }
}
export default useFetchUnread