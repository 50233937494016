import { useEffect, useState } from 'react';
import { CloseButton, Form, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useCreateEditCommission from '../hooks/useCreateEditCommission';
import DualListbox from './DualListBox';
import DualListBox from './DualListBox';

function AddEdit(props) {
  const commissionId = props.data?.id;

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedChairman, setSelectedChairman] = useState();
  const [selectedViceChairman, setSelectedViceChairman] = useState();

  const handleSelectionChange = (ids) => {
    setSelectedIds(ids);
  };

  const handleChairman = (id) => {
    setSelectedChairman(id);
  };

  const handleViceChairman = (id) => {
    setSelectedViceChairman(id);
  };


  const [formValues, setFormValues] = useState({
    name: props.data?.name || '',
    image: null,
    image2: null,
    live_url: props.data?.live_id ? "https://www.youtube.com/watch?v=" + props.data?.live_id : "" || "",
    live: props.data?.live || 0,
    selectedCouncilors: selectedIds || [],
  });
  const [file, setFile] = useState(props.data?.path || null);
  const [file2, setFileTwo] = useState(props.data?.path2 || null);
  const [image, setImage] = useState(null);
  const [image2, setImageTwo] = useState(null);

  const { handleSave, loading, validations, handleValidations } = useCreateEditCommission(commissionId, props.page, props.onHide);

  function validate(fieldName) {
    return handleValidations().validate(fieldName)
  }

  function resetForm() {
    setFormValues({
      name: '',
      image: null,
      image2: null,
      live: 0,
      selectedCouncilors: [],
    });
    setFile(null)
    setFileTwo(null)
    setImage(null)
    setImageTwo(null)
  }

  const onFormSubmit = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('live', Number(formValues.live));
    formData.append('live_url',formValues.live_url);
    if (image) {
      formData.append('path', image);
    }
    if (image2) {
      formData.append('path2', image2)
    }
    if (commissionId) {
      formData.append('id', commissionId);
    }
    formData.append('councilorIds', selectedIds);
    formData.append('chairman_id', selectedChairman);
    formData.append("vice_chairman_id", selectedViceChairman);
    handleSave(formData, resetForm);
  };

  const handleRemoveImage = e => {
    const { name, value, type, files } = e.target
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: null,
    }))
    if (name === 'path') {
      setFile(null)
      setImage("null")
    }
    if (name === 'path2') {
      setFileTwo(null)
      setImageTwo("null")
    }
  }

  const handleChange = e => {
    const { name, value, type, checked, files } = e.target;
    if (type === 'file') {
      if (name === 'path') {
        setFile(URL.createObjectURL(files[0]));
        setImage(files[0]);
        setFormValues(prevValues => ({
          ...prevValues,
          [name]: files[0],
        }));
      }
      else if (name === 'path2') {
        setFileTwo(URL.createObjectURL(files[0]));
        setImageTwo(files[0]);
        setFormValues(prevValues => ({
          ...prevValues,
          [name]: files[0],
        }));
      }
    } else {
      setFormValues(prevValues => ({
        ...prevValues,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.type === 'edit' ? 'Редакция' : 'Добавяне'}
        </Modal.Title>
        <CloseButton onClick={props.onHide} variant='white' />
      </Modal.Header>
      <div className="sticky-container">
        <Modal.Body>
          <Form id='commission-form' onSubmit={onFormSubmit}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label><strong>Име на комисия</strong></Form.Label>
              <Form.Control
                name='name'
                type="text"
                placeholder="Въведете име"
                value={formValues.name}
                onChange={handleChange}
              />
              {validate("name").message ? (
                <Form.Text style={{ color: 'red' }}>{validate("name").message}</Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            {/* {commissionId ?
              <div>
                <Form.Label><strong>Връзка към поток на заседанието</strong></Form.Label>
                <Form.Control
                  name='live_url'
                  type="text"
                  placeholder="Въведете връзка"
                  value={formValues.live_url}
                  onChange={handleChange}
                />
                {validate("live_url").message ? (
                  <Form.Text style={{ color: 'red' }}>{validate("live_url").message}</Form.Text>
                ) : (
                  <></>
                )}
                <br/>
              </div>              
              : <></>
            } */}
            {commissionId ?
              <></> :
              <>
                <Form.Group className="mb-3" controlId="formBasicImage">
                  <Form.Label><strong>Качете снимка</strong></Form.Label>
                  <br />
                  <input
                    id="fileInput"
                    name="path"
                    accept="image/*"
                    type="file"
                    onChange={handleChange}
                    style={{ display: 'none' }}
                  />
                  <label style={{ marginBottom: '10px' }} htmlFor="fileInput">
                    <Button as="span" variant="primary" className='app-button'>
                      Прикачете снимка
                    </Button>
                  </label>
                  <span style={{ marginLeft: '10px' }}>{formValues.image}</span>
                  {validate("path").message ? (
                    <Form.Text style={{ color: 'red' }}>{validate("path").message}</Form.Text>
                  ) : (
                    <></>
                  )}
                  <br />
                  {file && <div style={{ position: 'relative', display: 'inline-block' }}>
                    <Image style={{ maxHeight: '100px' }} src={file} thumbnail />
                    <CloseButton
                      name='path'
                      onClick={handleRemoveImage}
                      className='close-button'
                    />
                  </div>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicImage">
                  <Form.Label><strong>Качете втора снимка</strong></Form.Label>
                  <br />
                  <input
                    id="fileInput2"
                    name="path2"
                    accept="image/*"
                    type="file"
                    onChange={handleChange}
                    style={{ display: 'none' }}
                  />
                  <label style={{ marginBottom: '10px' }} htmlFor="fileInput2">
                    <Button as="span" variant="primary" className='app-button'>
                      Прикачете снимка
                    </Button>
                  </label>
                  <span style={{ marginLeft: '10px' }}>{formValues.image2}</span>
                  {validate("path2").message ? (
                    <Form.Text style={{ color: 'red' }}>{validate("path2").message}</Form.Text>
                  ) : (
                    <></>
                  )}
                  <br />
                  {file2 && <div style={{ position: 'relative', display: 'inline-block', height: 'auto', width: 'auto' }}>
                    <Image style={{ maxHeight: '100px' }} src={file2} thumbnail />
                    <CloseButton
                      name='path2'
                      onClick={handleRemoveImage}
                      className='close-button'
                    />
                  </div>}
                </Form.Group>
              </>}
            {/* <Form.Group className="mb-3" controlId="formBasicLive">
          {props.type === 'edit' ? <Form.Check
              name='live'
              type="switch"
              label="На живо"
              checked={formValues.live}
              onChange={handleChange}
            /> : <></>}
          </Form.Group> */}
            <Form.Group className="mb-3" controlId="formBasicImage">
              <Form.Label><strong>Изберете съветници</strong></Form.Label>
              <DualListBox
                preSelectedItems={props.data?.councilors}
                onSelectionChange={handleSelectionChange}
                onViceChairmanChange={handleViceChairman}
                onChairmanChange={handleChairman}

              />
            </Form.Group>
          </Form>
        </Modal.Body>
      </div>
      <Modal.Footer className='sticky-div'>
        <Button variant="primary" className='app-button' form='commission-form' type="submit" disabled={loading}>
          {props.type === 'edit' ? 'Запази' : 'Добави'}
        </Button>
        <Button variant='secondary' onClick={props.onHide}>Затвори</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEdit;
