import { Spinner } from "react-bootstrap";
import "../../../assets/scss/app.scss"

function Loader() {
    return (
        <>
        <div className="loading-spinner">
        <Spinner animation="border" role="status" size="bg" style={{height:'50px',width:'50px',borderWidth:'10px',placeContent:'center'}} />
        </div>
        </>
    );
}
export default Loader