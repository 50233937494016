import { useEffect, useState } from "react"
import { ENDPOINTS } from "../../../constants/endpoints"
import useFetch from "../../../hooks/useFetch"

function useFetchCommissions() {
    const {fetch,loading} = useFetch()

    const [commissions,setCommissions] = useState([])
    useEffect(() => {
        _fetchData()
    }, [])
    
    function _fetchData() {
        fetch({endpoint: ENDPOINTS.COMMISSIONS.GET_ALL,method:'get'})
        .then(res => {
            setCommissions(res?.data)
            console.log("COMMISSIONS",commissions)
        })
    }

    function getAllCommissions() {
        return commissions
    }

    function refetchData() {
        _fetchData()
    }

    return {
        getAllCommissions,
        refetchData,
        loading
    }
}
export default useFetchCommissions