import { createContext, useContext, useState } from "react";
import useFetchUnread from "../hooks/useFetchUnread";

export const UnreadCouncilorsDataContext = createContext({})

const UnreadCouncilorsDataProvider = props => {
    const { getUnreadCouncilors, refetchUnreadData, getCurrentPageUnread,setPageUnread,loading,getUnreadTabTitle } = useFetchUnread()
    
    const exportedData = {       
        getUnreadCouncilors,
        refetchUnreadData,
        getCurrentPageUnread,
        setPageUnread,
        getUnreadTabTitle
    }
    return <UnreadCouncilorsDataContext.Provider value={exportedData} {...props} />
}
export const useUnreadCouncilorsDataContext = () => useContext(UnreadCouncilorsDataContext)

export default UnreadCouncilorsDataProvider