import { useEffect, useState } from "react"
import { ENDPOINTS } from "../../../constants/endpoints"
import useFetch from "../../../hooks/useFetch"

function useFetchRoles() {
    const {fetch,loading} = useFetch()

    const [roles,setRoles] = useState([])
    useEffect(() => {
        _fetchData()
    }, [])
    
    function _fetchData() {
        fetch({endpoint: `${ENDPOINTS.ROLES.GET}`,method:'get'})
        .then(res => {
            setRoles(res.data)
        })
    }

    function getRoles() {
        return roles
    }

    function refetchData(page) {
        _fetchData(page)
    }

    return {
        getRoles,
        refetchData,
        loading
    }
}
export default useFetchRoles