import { createContext, useContext, useState } from "react";
import useFetchSessions from "../hooks/useFetchSessions";
import useFetchUpcomingSessions from "../hooks/useFetchUpcomingSessions";

export const UpcomingSessionsDataContext = createContext({})

const UpcomingSessionsDataProvider = props => {
    const { getUpcomingSessions,  refetchDataUpcoming, getCurrentPageUpcoming, setPageUpcoming,loading } = useFetchUpcomingSessions()
    
    const exportedData = {       
        getUpcomingSessions,
        refetchDataUpcoming,
        getCurrentPageUpcoming,
        setPageUpcoming,
        loading
    }
    return <UpcomingSessionsDataContext.Provider value={exportedData} {...props} />
}
export const useUpcomingSessionsDataContext = () => useContext(UpcomingSessionsDataContext)

export default UpcomingSessionsDataProvider