import { useEffect, useState } from "react"
import { ENDPOINTS } from "../../../constants/endpoints"
import useFetch from "../../../hooks/useFetch"

function useFetchTrashedCouncilors() {
    const {fetch,loading} = useFetch()

    const [councilors,setCouncilors] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    useEffect(() => {
        _fetchData(currentPage)
    }, [currentPage])
    
    function _fetchData() {
        fetch({endpoint: `${ENDPOINTS.COUNCILOR.GET_TRASHED}?page=${currentPage}`,method:'get'})
        .then(res => {
            setCouncilors(res.data)
        })
    }

    function getTrashedCouncilors() {
        return councilors
    }

    function getCurrentPageTrashed() {
        return currentPage
    }

    function setPageTrashed(page) {
        setCurrentPage(page)
    }

    function refetchTrashedData(page) {
        _fetchData(page)
    }

    return {
        getTrashedCouncilors,
        refetchTrashedData,
        getCurrentPageTrashed,
        setPageTrashed,
        loading
    }
}
export default useFetchTrashedCouncilors