import { createContext, useCallback, useContext, useMemo, useState } from "react";

export const FormDataContext = createContext({})

const FormDataProvider = props => {
    const INITIAL_FIELDS_STATE = {
        name: '',
        path: '',
        live: ''                
    }
    const [fields, setFields] = useState(INITIAL_FIELDS_STATE)

    function clearField(name) {
        setFields(prev => ({
            ...prev,
            [name]: ''
        }))
    }

    const getFields = useCallback(()=> {
        return fields
    },[fields])

    function resetFields() {
        setFields(INITIAL_FIELDS_STATE)
    }

    const exportedData = useMemo(() => {
        return {
            getFields,
            clearField,
            resetFields
        }
    },[fields])

    return <FormDataContext.Provider value={exportedData} {...props} />
}

export const useFormDataContext = () => useContext(FormDataContext)

export default FormDataProvider