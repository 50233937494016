import axios from "axios";

const api = axios.create({ 
    baseURL:`https://vtsavet-project.danielstandard.com/api`
    //baseURL: 'http://192.168.1.15/veliko-tarnovo-municipality-chat-api/public/api/'
})


api.interceptors.request.use(async config => {
    config.params = config.params || {}

    try {
        let token = localStorage.getItem('site') ?? null

        if(token) {

            if(token) {
                config.headers['Authorization'] = `Bearer ${token}`
            }
        }
    } catch (e) {
        //config.headers['Authorization'] = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xOTIuMTY4LjEuMzNcL3ZlbGlrby10YXJub3ZvLW11bmljaXBhbGl0eS1jaGF0LWFwaVwvcHVibGljXC9hcGlcL2F1dGhcL2xvZ2luIiwiaWF0IjoxNzI0MDU1MzIyLCJleHAiOjE3NTU1NTUzMjIsIm5iZiI6MTcyNDA1NTMyMiwianRpIjoiTkprczRTWGUzcTV5dXhzTiIsInN1YiI6NSwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.y9MzJiJ6-1sNuJjAgOevBag4RS2XyYeHHPNuw0QHuVw`
        console.error(e)
    }

    return config
})

export default api