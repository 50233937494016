import { useEffect, useState } from "react"
import { ENDPOINTS } from "../../../constants/endpoints"
import useFetch from "../../../hooks/useFetch"

function useFetchCommissions() {
    const {fetch,loading} = useFetch()

    const [commissions,setCommissions] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    useEffect(() => {
        _fetchData(currentPage)
    }, [currentPage])
    
    function _fetchData(page,refetch = false) {
        fetch({endpoint: `${ENDPOINTS.COMMISSIONS.GET}?page=${page}`,method:'get'})
        .then(res => {
            setCommissions(res.data)
            if(refetch == true) setPage(res.data.page)
        })
    }

    function getCommissions() {
        return commissions
    }

    function getCurrentPage() {
        return currentPage
    }

    function setPage(page) {
        setCurrentPage(page)
    }

    function refetchData(page) {
        _fetchData(page,true)
    }

    return {
        getCommissions,
        refetchData,
        getCurrentPage,
        setPage,
        loading
    }
}
export default useFetchCommissions