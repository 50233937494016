import { useEffect, useState } from "react";
import splashImage from "../../assets/images/app/splash.png"
import { Button, FloatingLabel, Form, Image } from "react-bootstrap"
import { useAuth } from "../../providers/authenticaion";

function Login() {
    const [input, setInput] = useState({
        email: "",
        password: "",
    });
    const [error, setError] = useState(""); // General error state
    const auth = useAuth();

    const handleSubmitEvent = (e) => {
        e.preventDefault();
        if (input.email !== "" && input.password !== "") {
            auth.loginAction(input)
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        setError("Invalid email or password"); // Set general error message
                    } else {
                        setError("An unexpected error occurred. Please try again later."); // Handle other errors
                    }
                });
        }
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
        setError(""); // Clear error message on input change
    };

    useEffect(() => {
        document.body.classList.add("splash");
        return () => {
            document.body.classList.remove("splash");
        }
    }, [])

    return (
        <section>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Image className="splash-image" height={200} src={splashImage} />                
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h4 style={{color:'white'}}>
                Моля, въведете вашите данни за вход
            </h4>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Form className="login-form" style={{ width: '20%', textAlign: 'center' }} id='login-form' onSubmit={handleSubmitEvent}>
                    <Form.Group className="mb-3" >
                        <FloatingLabel
                            label="Е-Поща"
                            className="mb-3"
                        >
                            <Form.Control
                                id="email"
                                placeholder="email@email.email"
                                name='email'
                                type="text"
                                onChange={handleInput}
                                isInvalid={!!error}
                            />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <FloatingLabel
                            label="Парола"
                            className="mb-3"
                        >
                            <Form.Control
                                id="password"
                                name='password'
                                type="password"
                                placeholder="password"
                                onChange={handleInput}
                                isInvalid={!!error}
                            />
                        </FloatingLabel>
                    </Form.Group>
                    {error && (
                        <div style={{ color: 'red', marginBottom: '10px' }}>
                            Грешни данни за вход!
                        </div>
                    )}
                    <div className="d-grid gap-2">
                        <Button variant="dark" form='login-form' type="submit">
                            Вход
                        </Button>
                    </div>
                </Form>
            </div>
        </section>
    )
}

export default Login;
