import { Alert, Button, CloseButton, Col, Container, Modal, Nav, OverlayTrigger, Pagination, Row, Table, Tooltip } from "react-bootstrap"
import useFetchThemes from "../hooks/useFetchThemes"
import Messages from "./Messages"
import { useState } from "react";
import Moment from "react-moment";

function Themes(props) {   
    
    const { getThemes } = useFetchThemes(props?.data?.id, props?.type)
    const [showMessages, setShowMessages] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState(null); // State to hold selected theme

    // Handle opening of Messages modal and pass specific theme data
    const handleShowMessages = (theme) => {
        setSelectedTheme(theme);
        setShowMessages(true);
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header className='modal-header'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props?.type == 'unread' ? 'Непрочетени чатове на ' + props.data.name  : 'Чатове на ' + props.data.name}
                    </Modal.Title>
                    <CloseButton onClick={props.onHide} variant='white' />
                </Modal.Header>
                <div className="sticky-container">
                    <Modal.Body>
                        {getThemes().length > 0 ?
                            <Table bordered hover>
                                <thead >
                                    <tr>
                                        <th style={{ width: '55%', height:'50px' }}>
                                            Тема на чат
                                        </th>
                                        <th style={{ width: '10%', height:'50px' }}>
                                            Оценка
                                        </th>
                                        <th style={{ width: '25%', height:'50px' }}>
                                            Дата на създаване
                                        </th>
                                        <th style={{ width: '10%', height:'50px' }}>
                                            Опции
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getThemes().map((c) => (
                                        <tr key={c.id}>
                                            <td>
                                                <strong>{c.name}</strong>
                                            </td>
                                            <td>
                                                {c?.grade ? <OverlayTrigger overlay={<Tooltip id={c?.grade?.id}>{c?.grade?.name}</Tooltip>}>
                                                    <img src={c?.grade?.path} alt={c?.grade?.name} />
                                                </OverlayTrigger> : 'Няма'}
                                            </td>
                                            <td>
                                               <Moment format="DD/MM/YYYY HH:mm">{c.created_at}</Moment>
                                            </td>
                                            <td>
                                                <Nav style={{paddingLeft:'0px',marginLeft:'0px',marginTop:'0px'}}>
                                                    <Nav.Link onClick={() => handleShowMessages(c)}  style={{padding:'0px'}}>Преглед</Nav.Link>
                                                </Nav>
                                            </td>
                                        </tr>
                                    ))
                                    }

                                </tbody>
                            </Table>
                            :

                            <>
                                <Alert variant="warning">
                                    <Alert.Heading>Няма намерени теми!</Alert.Heading>
                                    <p>
                                        Този съветник все още няма теми.
                                    </p>
                                </Alert>

                            </>
                        }
                    </Modal.Body>
                </div>
                <Modal.Footer className='sticky-div'>
                    <Button variant='secondary' onClick={props.onHide}>Затвори</Button>
                </Modal.Footer>
            </Modal>
            <Messages
                show={showMessages}
                onHide={() => setShowMessages(false)}
                data={selectedTheme}  // Pass selected theme data
                type={'edit'}
            />
        </>

    )
}
export default Themes