import { useEffect, useRef, useState } from 'react';
import { CloseButton, Dropdown, DropdownButton, Form, Image, ListGroup, OverlayTrigger, Toast, ToastContainer, Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useCreateEditSession from '../hooks/useCreateEditSession';
import documentImage from "../../../assets/images/app/pdfIcon.png";
import calendarImage from "../../../assets/images/app/calendar.svg"
import moment from 'moment';
import { useCommissionsDataContext } from '../providers/allCommissions';

function AddEdit(props) {

  const [selected, setSelected] = useState(props.data?.commission_id != 696969 ? {eventKey: 1, value: 'Комисия', id: 1} : { eventKey: 1, value: 'Сесия', id: 1 });
  const [showToast, setShowToast] = useState(false);
  const commissions = useCommissionsDataContext()
  const { getAllCommissions, refetchData } = commissions
  const sessionId = props.data?.id;
  const [formValues, setFormValues] = useState({
    name: props.data?.name || '',
    event_date: props.data?.event_date ? props.data.event_date : null,
    commission_id: props.data?.commission_id ?? undefined,
    live_url: props.data?.live_id ? "https://www.youtube.com/watch?v=" + props.data?.live_id : "" || "",
    type: props.data?.commission_id == 696969 ? 1 : 2,
  });
  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);

  const { handleSave, loading, validations, handleValidations } = useCreateEditSession(sessionId, props.onHide);

  function validate(fieldName) {
    return handleValidations().validate(fieldName)
  }

  function successToast()
  {
    setShowToast(true);    
    setTimeout(() => setShowToast(false), 3000);
  }

  function resetForm() {
    if (formValues.commission_id != 696969) {
      setFormValues({
        name: '',
        event_date: null,
        commission_id: formValues.commission_id,
        type: 2,
      })
      setSelected({ eventKey: 2, value: 'Комисия', id: 2 })
    }
    else {
      setFormValues({
        name: '',
        event_date: null,
        commission_id: 696969,
        type: 1,
      })
      setSelected({ eventKey: 1, value: 'Сесия', id: 1 })
    }
    setFiles([])
    setFilePreviews([])
  }

  const handleSelect = (eventKey, event) => {
    setSelected({ eventKey: event.target.id, value: eventKey });
    setFormValues(prevValues => ({
      ...prevValues,
      type: event.target.id,
    }));
  };

  const handleRemoveFile = (indexToRemove) => {
    // Remove the selected file from the files array
    setFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));

    // Remove the corresponding preview
    setFilePreviews(prevPreviews => prevPreviews.filter((_, index) => index !== indexToRemove));
  };


  const onFormSubmit = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('event_date', formValues.event_date);
    formData.append('commission_id', formValues.commission_id);
    formData.append('type', formValues.type);
    formData.append('live_url', formValues.live_url);
    if (formValues.type == 1) formData.append('commission_id', 696969);

    files.forEach((file, index) => {
      formData.append(`file[${index}]`, file);  // Append each file with the correct naming pattern
    });

    if (sessionId) {
      formData.append('id', sessionId);
    }

    handleSave(formData, resetForm, props.tab, true,successToast);
  };

  const handleChange = e => {
    const { name, value, type, checked, files: selectedFiles } = e.target;

    if (type === 'file') {
      const newFiles = Array.from(selectedFiles);
      setFiles(prevFiles => [...prevFiles, ...newFiles]);

      // Create file preview URLs and append them to existing previews
      const newPreviews = newFiles.map(file => URL.createObjectURL(file));
      setFilePreviews(prevPreviews => [...prevPreviews, ...newPreviews]);

      setFormValues(prevValues => ({
        ...prevValues,
        [name]: [...(prevValues[name] || []), ...newFiles],  // Append new files to existing ones
      }));
    } else {
      setFormValues(prevValues => ({
        ...prevValues,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.type === 'edit' ? 'Редакция' : 'Добавяне'}
        </Modal.Title>
        <CloseButton onClick={props.onHide} variant='white' />
      </Modal.Header>
      <div className="sticky-container">
        <Modal.Body>
          <Form id='session-form' onSubmit={onFormSubmit}>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <Form.Label><strong>Дата на заседание</strong></Form.Label>
              <Form.Control
                className='date-picker'
                name='event_date'
                type="datetime-local"
                placeholder="Въведете дата"
                style={{ position: 'relative' }}
                value={formValues.event_date}
                onChange={handleChange}
              />
              {validate("event_date").message ? (
                <Form.Text style={{ color: 'red' }}>{validate("event_date").message}</Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label><strong>Име на заседание</strong></Form.Label>
              <Form.Control
                name='name'
                type="text"
                placeholder="Въведете име"
                value={formValues.name}
                onChange={handleChange}
              />
              {validate("name").message ? (
                <Form.Text style={{ color: 'red' }}>{validate("name").message}</Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label><strong>Връзка към излъчване на живо</strong></Form.Label>
              <Form.Control
                name='live_url'
                type="text"
                placeholder="Въведете връзка"
                value={formValues.live_url}
                onChange={handleChange}
              />
              {validate("live_url").message ? (
                <Form.Text style={{ color: 'red' }}>{validate("live_url").message}</Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicImage">
              <Form.Label><strong>Качете материали</strong></Form.Label>
              <br />
              <input
                id="fileInput"
                name="path"
                accept=".pdf, .doc, .docx, .xls, .xlsx, .zip, .rar"
                multiple
                type="file"
                onChange={handleChange}
                style={{ display: 'none' }}
              />
              <label style={{ marginBottom: '10px' }} htmlFor="fileInput">
                <Button as="span" variant="primary" className='app-button'>
                  Прикачете файлове
                </Button>
              </label>

              {validate("path").message ? (
                <Form.Text style={{ color: 'red' }}>{validate("path").message}</Form.Text>
              ) : (
                <></>
              )}
              <br />
              {files && files.map((file, index) => (
                <div key={index} style={{ position: 'relative', display: 'inline-block', margin: '5px' }}>
                  <OverlayTrigger overlay={<Tooltip>{file.name}</Tooltip>} >
                    <div>
                      <Image style={{ maxHeight: '100px' }} src={documentImage} thumbnail />
                      <CloseButton
                        onClick={() => handleRemoveFile(index)}  // Remove file on click
                        className='close-button'
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              ))}
            </Form.Group>
            <Form.Label><strong>Изберете тип</strong></Form.Label>
            <Form.Group>
              <DropdownButton onSelect={handleSelect} variant='secondary' id="dropdown-basic-button" title={selected?.value || "Сесия"}>
                <Dropdown.Item value={1} id={1} eventKey={"Сесия"} key={1}>Сесия</Dropdown.Item>
                <Dropdown.Item value={2} id={2} eventKey={"Комисия"} key={2}>Комисия</Dropdown.Item>
              </DropdownButton>
            </Form.Group>
            <br />
            {formValues.type == 2 ?
              <>
                <Form.Label><strong>Изберете комисия</strong></Form.Label>
                <br />
                {validate("commission_id").message ? (
                  <Form.Text style={{ color: 'red' }}>{validate("commission_id").message}</Form.Text>
                ) : (
                  <></>
                )}
                <ListGroup>
                  {getAllCommissions().data?.map((c) => (
                    <ListGroup.Item
                      key={c.id}
                      onClick={() => handleChange({ target: { name: 'commission_id', value: c.id } })}
                      style={{ cursor: 'pointer' }}
                    >
                      <Form.Group>
                        <Form.Label style={{ fontSize: '15px' }}>{c.name}</Form.Label>
                        <Form.Check.Input
                          style={{ float: 'right' }}
                          name='commission_id'
                          value={c.id}
                          onChange={handleChange}
                          type="radio"
                          checked={formValues.commission_id == c.id ? true : undefined}
                          isValid
                        />
                      </Form.Group>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
              : <></>}
          </Form>
        </Modal.Body>
        <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
        <Toast bg='success' show={showToast}>
          <Toast.Header>
            <strong className="me-auto">Успех</strong>
            <small className="text-muted">just now</small>
          </Toast.Header>
          <Toast.Body><p style={{color:'white'}}>Промените бяха запазени успешно</p></Toast.Body>
        </Toast>
      </ToastContainer>
      </div>
      <Modal.Footer className='sticky-div'>
        <Button variant="primary" className='app-button' form='session-form' type="submit" disabled={loading}>
          {props.type === 'edit' ? 'Запази' : 'Добави'}
        </Button>
        <Button variant='secondary' onClick={props.onHide}>Затвори</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEdit;
