import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

function NoResults({ type }) {
  function renderType() {
    switch (type) {
      case 'commissions':
        return (
          <p>
            В момента няма добавени комисии.
            <br />
          </p>
        )
      case 'councilors':
        return (
          <p>
            В момента няма регистрирани съветници.
            <br />
          </p>
        )
      case 'users':
        return (
          <p>
            В момента няма регистрирани потребители.
          </p>
        )
      case 'sessions':
        return (
          <p>
            В момента няма добавени заседания.
            <br />
          </p>
        )
      case 'expired':
        return (
          <p>
            В момента няма съветници с преминал мандат.
            <br />
          </p>
        )
      case 'unread':
        return (
          <p>
            В момента няма съветници с непрочетени съобщения.
            <br />
          </p>
        )
      default:
        return (
          <p>
            Можете да добавите комисии тук:
            <br />
          </p>
        )
    }
  }
  return (
    <Alert style={{ textAlign: 'center' }} variant="warning">
      <Alert.Heading>Няма намерени резултати!</Alert.Heading>
      {renderType()}
    </Alert>
  );
}
export default NoResults