import { useEffect, useState } from "react"
import { ENDPOINTS } from "../../../constants/endpoints"
import useFetch from "../../../hooks/useFetch"

function useFetchSessions() {
    const {fetch,loading} = useFetch()

    const [sessions,setSessions] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    useEffect(() => {
        _fetchData(currentPage)
    }, [currentPage])
    
    function _fetchData(page,refetch = false) {
        fetch({endpoint: `${ENDPOINTS.SESSIONS.GET}?page=${page}`,method:'get'})
        .then(res => {
            setSessions(res?.data)
            setPage(res.data.page)
        })
    }

    function getSessions() {
        return sessions
    }

    function getCurrentPage() {
        return currentPage
    }

    function setPage(page) {
        setCurrentPage(page)
    }

    function refetchData(page) {
        _fetchData(page,true)
    }

    return {
        getSessions,
        refetchData,
        getCurrentPage,
        setPage,
        loading
    }
}
export default useFetchSessions