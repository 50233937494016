import { useEffect, useState } from 'react';
import { CloseButton, Form, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Moment from 'react-moment';
import useGoLive from '../hooks/useGoLive';

function Live(props) {
    const commissionId = props.data?.commission_id;
    const sessionId = props.data?.id;

    const [formValues, setFormValues] = useState({
        live: props.data?.live || 0,
        live_url: props.data?.live_id ? "https://www.youtube.com/watch?v=" + props.data?.live_id : "" || "",
        commission_id: props.data?.commission_id || null,
        session_id: props.data?.id || null,
    });

    const { handleSave, loading, validations, handleValidations } = useGoLive(props.page);

    function validate(fieldName) {
        return handleValidations().validate(fieldName)
    }

    const onFormSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('live_url', formValues.live_url);
        formData.append('live', Number(formValues.live));
        if (sessionId) {
            formData.append('session_id', sessionId)
        }
        if (commissionId) {
            formData.append('commission_id', commissionId);
        }
        handleSave(formData,props.onHide);
    };

    const handleChange = e => {
        const { name, value, type, checked } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,            
            [name]: type === 'checkbox' ? checked : value,            
        }));
        if(name === 'live_url' && value != null) {
            setFormValues(prevValues => ({
                ...prevValues,
                live: true,
            }));
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h6>
                        {props.data.commission_name}
                    </h6>
                </Modal.Title>
                <CloseButton onClick={props.onHide} variant='white' />
            </Modal.Header>
            <Modal.Body>
                <Form id='commission-form' onSubmit={onFormSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label><strong>Линк към заседание</strong></Form.Label>
                        <Form.Control
                            name='live_url'
                            type="text"
                            placeholder="e.g. https://www.youtube.com/watch?v=AjWfY7SnMBI"

                            value={formValues.live_url}
                            onChange={handleChange}
                        />
                        {validate("live_url").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("live_url").message}</Form.Text>
                        ) : (
                            <></>
                        )}                        
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicLive">
                        <Form.Check
                            name='live'
                            type="switch"
                            label="На живо"
                            checked={formValues.live}
                            onChange={handleChange}
                        />
                        {validate("live").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("live").message}</Form.Text>
                        ) : (
                            <></>
                        )}
                        
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className='app-button' form='commission-form' type="submit" disabled={loading}>
                    Запази
                </Button>
                <Button variant='secondary' onClick={props.onHide}>Затвори</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Live;
