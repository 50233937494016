import { Alert, Button, Col, Container, Pagination, Row, Table } from "react-bootstrap"
import Session from "./components/Session"
import { useSessionsDataContext } from "./providers/sessions"
import { useEffect, useState } from "react"
import useFetchSessions from "./hooks/useFetchSessions"
import Loader from "../app/components/Loader"
import NoResults from "../app/components/NoResults"
import PastSessions from "./components/PastSessions"
import FutureSessions from "./components/FutureSessions"
import addImage from "../../assets/images/app/add.svg"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AddEdit from "./components/AddEdit"
import { usePastSessionsDataContext } from "./providers/pastSessions"
import { useUpcomingSessionsDataContext } from "./providers/upcomingSessions"


function Sessions() {
    const sessions = useSessionsDataContext()
    const { getSessions, getCurrentPage, refetchData, setPage, loading } = sessions

    const pastSessions = usePastSessionsDataContext()
    const { getPastSessions, getCurrentPagePast, setPagePast } = pastSessions

    const upcomingSessions = useUpcomingSessionsDataContext()
    const { getUpcomingSessions, getCurrentPageUpcoming, setPageUpcoming } = upcomingSessions

    const [modalShow, setModalShow] = useState(false)

    const [windowType, setWindowType] = useState('upcoming');

    function changeWindowType(type) {
        setWindowType(type);
    }


    function changePage(newPage) {
        setPage(newPage)
    }
    function changeUpcomingPage(newPage) {
        setPageUpcoming(newPage)
    }
    function changePastPage(newPage) {
        setPagePast(newPage)
    }

    function createUpcomingPagination() {

        const maxPagesToShow = 10;
        const pageItems = [];


        pageItems.push(
            <Pagination.Item key={1} active={1 === parseInt(getCurrentPageUpcoming())} onClick={() => changeUpcomingPage(1)}>
                1
            </Pagination.Item>
        );

        if (getCurrentPageUpcoming() > 3) {
            pageItems.push(<Pagination.Ellipsis key="start-ellipsis" />)
        }

        const startPage = Math.max(2, parseInt(getCurrentPageUpcoming()) - 1);
        const endPage = Math.min(parseInt(getCurrentPageUpcoming()) + 2, parseInt(getUpcomingSessions().pages) - 1);

        for (let number = startPage; number <= endPage; number++) {
            pageItems.push(
                <Pagination.Item key={number} active={number === parseInt(getCurrentPageUpcoming())} onClick={() => changeUpcomingPage(number)}>
                    {number}
                </Pagination.Item>,
            );
        }

        // Show ellipsis before the last page if necessary
        if (parseInt(getCurrentPageUpcoming()) + 2 < parseInt(getUpcomingSessions().pages) - 1) {
            pageItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }

        // Always show the last page if there are more than one page
        if (parseInt(getUpcomingSessions().pages) > 1) {
            pageItems.push(
                <Pagination.Item key={parseInt(getUpcomingSessions().pages)} active={parseInt(getUpcomingSessions().pages) === parseInt(getCurrentPageUpcoming())} onClick={() => changeUpcomingPage(getUpcomingSessions().pages)}>
                    {getUpcomingSessions().pages}
                </Pagination.Item>
            );
        }

        return pageItems;
    }

    function createPastPagination() {
        const pageItems = [];

        pageItems.push(
            <Pagination.Item key={1} active={1 == parseInt(getCurrentPagePast())} onClick={() => changePastPage(1)}>
                1
            </Pagination.Item>
        );

        if (parseInt(getCurrentPagePast()) > 3) {
            pageItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }

        const startPage = Math.max(2, parseInt(getCurrentPagePast()) - 1);
        const endPage = Math.min(parseInt(getCurrentPagePast()) + 2, parseInt(getPastSessions().pages) - 1);

        for (let number = startPage; number <= endPage; number++) {
            pageItems.push(
                <Pagination.Item key={number} active={number == parseInt(getCurrentPagePast())} onClick={() => changePastPage(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        if (parseInt(getCurrentPagePast()) + 2 < parseInt(getPastSessions().pages) - 1) {
            pageItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }

        // Always show the last page if there are more than one page
        pageItems.push(
            <Pagination.Item key={getPastSessions().pages} active={getPastSessions().pages === parseInt(getCurrentPagePast())} onClick={() => changePastPage(getPastSessions().pages)}>
                {getPastSessions().pages}
            </Pagination.Item>
        );

        return pageItems;
    }


    function renderUpcomingSessions() {
        return (
            getUpcomingSessions().data?.upcoming?.length > 0 ? <FutureSessions data={getUpcomingSessions().data?.upcoming} /> :
                <>
                    <Alert variant="warning">
                        <Alert.Heading style={{ textAlign: 'center' }}>В момента няма предстоящи заседания</Alert.Heading>
                    </Alert>
                </>
        )
    }

    function renderPastSessions() {
        return (
            getPastSessions().data?.past?.length > 0 ? <PastSessions data={getPastSessions().data?.past} /> : <></>
        )
    }
    function renderTable() {
        return (
            <>
                <Tabs
                    defaultActiveKey="upcoming"
                    id="justify-tab-example"
                    className="mb-3"
                    transition
                    style={{ fontWeight: 'bold', marginTop: '20px', paddingLeft: '30%', paddingRight: '30%' }}
                    variant="pills"
                    justify
                    fill
                    onSelect={(eventKey) => changeWindowType(eventKey)}
                >
                    <Tab eventKey="upcoming" title="Предстоящи заседания">
                        {renderUpcomingSessions()}
                        <div>
                            {getUpcomingSessions().pages > 1 ? <Pagination style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} centered>{createUpcomingPagination()}</Pagination> : <></>}


                        </div>
                    </Tab>
                    <Tab eventKey="past" title="Минали заседания" >
                        {renderPastSessions()}
                        <div>
                            {getPastSessions().pages > 1 ? <Pagination style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} centered>{console.log(createPastPagination())}{createPastPagination()}</Pagination> : <></>}
                        </div>
                    </Tab>
                </Tabs>
            </>
        )
    }
    function results() {
        return getSessions().pages > 0 ? renderTable() : <NoResults type={'sessions'} />
    }
    return (
        <>
            <Container fluid="true" style={{ marginBottom: '25px' }}>
                <Row style={{ width: '100%' }}>
                    <Col style={{ marginLeft: '5px' }}>
                        <h1>График</h1>
                    </Col>
                    <Col style={{ marginRight: '0px' }}>
                        <Button onClick={() => setModalShow(true)} variant="success" style={{ float: 'right', marginTop: '10px' }} ><img style={{ margin: '3px', marginRight: '6px' }} src={addImage} />Добави заседание</Button>
                    </Col>
                </Row>
            </Container >
            {sessions.loading == false ? results() : <Loader />}
            <AddEdit
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={null}
                tab={windowType}
                type={'add'}
            />            
        </>

    )
}
export default Sessions