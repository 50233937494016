import { Image, Nav, Table } from "react-bootstrap"
import { useEffect, useState } from "react"
import removeImage from "../../../assets/images/app/remove.svg"
import editImage from "../../../assets/images/app/edit.svg"
import testImg from "../../../assets/images/app/manTestImg.jpg"
import defaultAvatar from "../../../assets/images/app/default-avatar.png"
import useConvertMinutes from "../../../hooks/useConvertMinutes"
import AddEdit from "./AddEdit"
import Delete from "./Delete"
import chatImage from "../../../assets/images/app/chat.svg"
import passwordImage from "../../../assets/images/app/password.svg"
import Themes from "./Themes"
import EditPassword from "./EditPassword"


function UnreadCouncilor({ roles, data }) {
    const { name, firstname, lastname, email, average_rating, number, is_councilor, path, role_id, replies_within, full_name, role } = data
    //const {name:roleName, id,abbr} = role
    const [rating, setRating] = useState(average_rating)
    const { days, hours, minutes, responseTime } = useConvertMinutes(replies_within)
    const [modalShow, setModalShow] = useState(false);
    const [showThemes, setThemes] = useState(false);

    function starRating() {
        return (
            <>
                {[1, 2, 3, 4, 5].map((star) => {
                    return (
                        <span
                            className='start'
                            style={{
                                color: rating >= star ? 'gold' : 'gray',
                                fontSize: `20px`,
                            }}
                        >
                            {' '}
                            ★{' '}
                        </span>
                    )
                })}
            </>
        )
    }

    return (
        <>
            <tr>
                <td>
                    <Image src={path ?? defaultAvatar} roundedCircle style={{ height: '50px', width: '50px' }} />
                </td>
                <td>
                    <strong>{firstname} {lastname}</strong>
                </td>
                <td>
                    {role_id ? (role.name ?? "Член") : "Член"}
                </td>
                <td>
                    {starRating()}
                </td>
                <td>
                    {responseTime != null ? "Отговаря в рамките на " + responseTime : ""}
                </td>
                <td>
                    <Nav style={{ fontSize: '12px' }}>
                        <Nav.Link onClick={() => setThemes(true)}><Image style={{ filter: 'brightness(10%)' }} src={chatImage} /></Nav.Link>
                    </Nav>
                </td>
            </tr>
            <Themes
                show={showThemes}
                onHide={() => setThemes(false)}
                data={data}
                roles={roles}
                type={'unread'}
            />
        </>

    )
}
export default UnreadCouncilor