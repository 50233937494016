import { useEffect, useState } from "react"
import { ENDPOINTS } from "../../../constants/endpoints"
import useFetch from "../../../hooks/useFetch"

function useFetchUpcomingSessions() {
    const {fetch,loading} = useFetch()

    const [sessions,setSessions] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    useEffect(() => {
        _fetchData(currentPage)
    }, [currentPage])
    
    function _fetchData(page,refetch = false) {
        fetch({endpoint: `${ENDPOINTS.SESSIONS.GET_UPCOMING}?page=${page}`,method:'get'})
        .then(res => {
            setSessions(res?.data)
            if(refetch == true) setPageUpcoming(res.data.page)
        })
    }

    function getUpcomingSessions() {
        return sessions
    }

    function getCurrentPageUpcoming() {
        return currentPage
    }

    function setPageUpcoming(page) {
        setCurrentPage(page)
    }

    function refetchDataUpcoming(page) {
        _fetchData(page,true)
    }

    return {
        getUpcomingSessions,
        refetchDataUpcoming,
        getCurrentPageUpcoming,
        setPageUpcoming,
        loading
    }
}
export default useFetchUpcomingSessions