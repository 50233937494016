import { createContext, useContext, useState } from "react";
import useFetchCommissions from "../hooks/useFetchCommissions";

export const CommissionsDataContext = createContext({})

const AllCommissionsDataProvider = props => {
    const { getAllCommissions, refetchData, loading } = useFetchCommissions()
    
    const exportedData = {       
        getAllCommissions,
        refetchData,
        loading
    }
    return <CommissionsDataContext.Provider value={exportedData} {...props} />
}
export const useCommissionsDataContext = () => useContext(CommissionsDataContext)

export default AllCommissionsDataProvider