import { createContext, useContext, useState } from "react";
import useFetchSessions from "../hooks/useFetchSessions";

export const SessionsDataContext = createContext({})

const SessionsDataProvider = props => {
    const { getSessions, refetchData, getCurrentPage,setPage,loading } = useFetchSessions()
    
    const exportedData = {       
        getSessions,
        refetchData,
        getCurrentPage,
        setPage,
        loading
    }
    return <SessionsDataContext.Provider value={exportedData} {...props} />
}
export const useSessionsDataContext = () => useContext(SessionsDataContext)

export default SessionsDataProvider