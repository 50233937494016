import { createContext, useContext, useState } from "react";
import useFetchCouncilors from "../hooks/useFetchCouncilors";

export const CouncilorsDataContext = createContext({})

const CouncilorsDataProvider = props => {
    const { getCouncilors, refetchData, getCurrentPage,setPage,loading } = useFetchCouncilors()
    
    const exportedData = {       
        getCouncilors,
        refetchData,
        getCurrentPage,
        setPage,
        loading
    }
    return <CouncilorsDataContext.Provider value={exportedData} {...props} />
}
export const useCouncilorsDataContext = () => useContext(CouncilorsDataContext)

export default CouncilorsDataProvider