import { useEffect, useState } from "react"
import useFetch from "../../../hooks/useFetch"
import { useFormDataContext } from "../../../providers/form"
import { ENDPOINTS } from "../../../constants/endpoints"
import { useCouncilorsDataContext } from "../providers/councilors"
import { useToast } from "../../app/components/ToastContext"

function useCreateEditCouncilor(councilorId = null,onHide) {
    const councilorData = useCouncilorsDataContext()
    const formData = useFormDataContext()
    const {successToast} = useToast()

    const { fetch, loading, validations, handleValidations } = useFetch()

    async function handleSave(data,resetForm) {
        let auth = localStorage.getItem('site')
        const token = auth
        if (loading) return
        handleValidations().clear()

        const endpoint = councilorId ? ENDPOINTS.COUNCILOR.UPDATE : ENDPOINTS.COUNCILOR.CREATE
        const method = "post"

        try {
            const response = await fetch({
                endpoint,
                method,
                data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            })

            if (response.status === 200) {
                councilorData.refetchData(councilorData.getCurrentPage())
                resetForm()
                onHide()
                successToast(councilorId ? 'Успешно запазихте промените' : 'Успешно добавихте съветник')
                
            } else {
                console.error("Request failed with status: ", response.status)
            }
        } catch (error) {
            console.error('An error occured:', error)
        }
    }

    return {
        loading,
        handleSave,
        validations,
        handleValidations
    }
}
export default useCreateEditCouncilor
