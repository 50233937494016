import { createContext, useContext, useState } from "react";
import useFetchUsers from "../hooks/useFetchUsers";

export const UsersDataContext = createContext({})

const UsersDataProvider = props => {
    const { getUsers, refetchData, getCurrentPage,setPage,loading } = useFetchUsers()
    
    const exportedData = {       
        getUsers,
        refetchData,
        getCurrentPage,
        setPage,
        loading
    }
    return <UsersDataContext.Provider value={exportedData} {...props} />
}
export const useUsersDataContext = () => useContext(UsersDataContext)

export default UsersDataProvider