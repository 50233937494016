import { useEffect, useRef, useState } from 'react';
import { CloseButton, Dropdown, DropdownButton, Form, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useCreateEditUser from '../hooks/useCreateEditUser';

function AddEdit(props) {
    
    const userId = props.data?.id;

    const [formValues, setFormValues] = useState({
        name: props.data?.name || '',
        lastname: props.data?.lastname || '',
        email: props.data?.email || '',
        number: props.data?.number || '',
        is_councilor: props.data?.is_councilor || '',
        address: props.data?.address || '',
    });

    const formRef = useRef()
    function resetForm() {
        formRef.current.reset()
        handleValidations().clear()
    }

    const { handleSave, loading, validations, handleValidations } = useCreateEditUser(userId,resetForm);

    function validate(fieldName) {
        return handleValidations().validate(fieldName)
    }

    

    const onFormSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', formValues.name);
        formData.append('firstname', formValues.name);
        formData.append('lastname', formValues.lastname);
        formData.append('email', formValues.email);
        formData.append('number', formValues.number);
        formData.append('is_councilor', Number(formValues.is_councilor));
        formData.append('address', formValues.address);
        if (userId) {
            formData.append('id', userId);
        }
        handleSave(formData);
    };

    const handleChange = e => {
        const { name, value, type, checked, files } = e.target;

        setFormValues(prevValues => ({
            ...prevValues,
            [name]: type === 'checkbox' ? checked : value,
        }));

    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.type === 'edit' ? 'Редакция' : 'Добавяне'}
                </Modal.Title>
                <CloseButton onClick={props.onHide} variant='white' />
            </Modal.Header>
            <div className="sticky-container">
            <Modal.Body>
                <Form id='councilor-form' onSubmit={onFormSubmit} ref={formRef}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label><strong>Име</strong></Form.Label>
                        <Form.Control
                            name='name'
                            type="text"
                            placeholder="Въведете име"
                            value={formValues.name}
                            onChange={handleChange}
                        />

                        {validate("name").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("name").message}</Form.Text>
                        ) : (
                            <></>
                        )}

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label><strong>Фамилия</strong></Form.Label>
                        <Form.Control
                            name='lastname'
                            type="text"
                            placeholder="Въведете фамилия"                            
                            value={formValues.lastname}
                            onChange={handleChange}
                        />
                        {validate("lastname").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("lastname").message}</Form.Text>
                        ) : (
                            <></>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label><strong>Е-поща</strong></Form.Label>
                        <Form.Control
                            name='email'
                            type="text"
                            placeholder="Въведете е-поща"
                            value={formValues.email}
                            onChange={handleChange}
                        />
                        {validate("email").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("email").message}</Form.Text>
                        ) : (
                            <></>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label><strong>Телефонен номер</strong></Form.Label>
                        <Form.Control
                            name='number'
                            type="text"
                            placeholder="Въведете номер"                            
                            value={formValues.number}
                            onChange={handleChange}
                        />
                        {validate("number").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("number").message}</Form.Text>
                        ) : (
                            <></>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label><strong>Адрес</strong></Form.Label>
                        <Form.Control
                            name='address'
                            type="text"
                            placeholder="Въведете адрес"                            
                            value={formValues.address}
                            onChange={handleChange}
                        />
                        {validate("address").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("address").message}</Form.Text>
                        ) : (
                            <></>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicIsCouncilor">
                        <Form.Check
                            name='is_councilor'
                            type="switch"
                            label="Съветник"
                            checked={formValues.is_councilor}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            </div>
            <Modal.Footer className='sticky-div'>
                <Button variant="primary" className='app-button' form='councilor-form' type="submit" disabled={loading}>
                    {props.type === 'edit' ? 'Запази' : 'Добави'}
                </Button>
                <Button variant='secondary' onClick={props.onHide}>Затвори</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEdit;
