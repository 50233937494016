import './assets/scss/app.scss';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Login from './components/login';
import Commissions from './components/commissions';
import CommissionsDataProvider from './components/commissions/providers/commissions';
import Header from './components/app/components/Header';
import Sessions from './components/sessions';
import SessionsDataProvider from './components/sessions/providers/sessions';
import Councilors from './components/councilors';
import CouncilorsDataProvider from './components/councilors/providers/councilors';
import AuthProvider from './providers/authenticaion';
import PrivateRoute from './components/privateroutes/PrivateRoute';
import UsersDataProvider from './components/users/providers/users';
import Users from './components/users';
import UpcomingSessionsDataProvider, { UpcomingSessionsDataContext } from './components/sessions/providers/upcomingSessions';
import PastSessionsDataProvider from './components/sessions/providers/pastSessions';
import AllCouncilorsDataProvider from './components/commissions/providers/councilors';
import AllCommissionsDataProvider from './components/sessions/providers/allCommissions';
import { ToastProvider } from './components/app/components/ToastContext';
import TrashedCouncilorsDataProvider from './components/councilors/providers/trashedCouncilors';
import UnreadCouncilorsDataProvider from './components/councilors/providers/councilorsUnread';

function RoutesComponent() {
  const location = useLocation()
    
  return (
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path='/' exact element={<AllCouncilorsDataProvider><CommissionsDataProvider><Commissions /></CommissionsDataProvider></AllCouncilorsDataProvider>} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/commissions' exact element={<AllCouncilorsDataProvider><CommissionsDataProvider><Commissions /></CommissionsDataProvider></AllCouncilorsDataProvider>} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/sessions' exact element={<AllCommissionsDataProvider><SessionsDataProvider><UpcomingSessionsDataProvider><PastSessionsDataProvider><Sessions /></PastSessionsDataProvider></UpcomingSessionsDataProvider></SessionsDataProvider></AllCommissionsDataProvider>} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/councilors' exact element={<UnreadCouncilorsDataProvider><CouncilorsDataProvider><TrashedCouncilorsDataProvider><Councilors /></TrashedCouncilorsDataProvider></CouncilorsDataProvider></UnreadCouncilorsDataProvider>} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/users' exact element={<UsersDataProvider><Users /></UsersDataProvider>} />
          </Route>
          <Route path='/login' exact element={<Login />} />
        </Routes>
  );
}

function App() {
  return (
    <div className="App">
      <ToastProvider>
      <Router>
        <AuthProvider>
        <Header />        
        <div>
          <RoutesComponent />
        </div>
        </AuthProvider>
      </Router>
      </ToastProvider>
    </div>
  );
}

export default App;
