import { useEffect } from "react"
import useFetch from "../../../hooks/useFetch"
import { ENDPOINTS } from "../../../constants/endpoints"
import { usePastSessionsDataContext } from "../providers/pastSessions"
import { useUpcomingSessionsDataContext } from "../providers/upcomingSessions"

function useGoLive() {
    const upcomingSessionData = useUpcomingSessionsDataContext()
    const pastSessionData = usePastSessionsDataContext()

    const { fetch, loading, validations, handleValidations } = useFetch()

    async function handleSave(data,onHide) {
        if (loading) return
        //handleValidations().clear()

        const endpoint = ENDPOINTS.COMMISSIONS.SET_LIVE
        const method = "post"

        try {
            const response = await fetch({
                endpoint,
                method,
                data,
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.status === 200) {                
                upcomingSessionData.refetchDataUpcoming(upcomingSessionData.getCurrentPageUpcoming())
                pastSessionData.refetchDataPast(pastSessionData.getCurrentPagePast())
                onHide()
            } else {
                console.error('Request failed with status:', response.status)
            }
        }
        catch (error) {
            console.error('An error occured:', error);
        }
    }

    return {
        loading,
        handleSave,
        validations,
        handleValidations
    }
}
export default useGoLive
