import { Button, Col, Container, Pagination, Row, Tab, Table, Tabs } from "react-bootstrap"
import { useEffect, useState } from "react"
import Councilor from "./components/Councilor"
import { useCouncilorsDataContext } from "./providers/councilors"
import NoResults from "../app/components/NoResults"
import Loader from "../app/components/Loader"
import addImage from "../../assets/images/app/add.svg"
import AddEdit from "./components/AddEdit"
import useFetchRoles from "./hooks/useFetchRoles"
import { useTrashedCouncilorsDataContext } from "./providers/trashedCouncilors"
import TrashedCouncilor from "./components/TrashedCouncilor"
import UnreadCouncilor from "./components/UnreadCouncilor"
import { useUnreadCouncilorsDataContext } from "./providers/councilorsUnread"

function Councilors() {

    const roleData = useFetchRoles();
    const { getRoles } = roleData;
    const roles = getRoles()?.data || [];
    const councilors = useCouncilorsDataContext()
    const trashedCouncilors = useTrashedCouncilorsDataContext()
    const unreadCouncilors = useUnreadCouncilorsDataContext()
    const { getCouncilors, refetchData, getCurrentPage, setPage, loading } = councilors
    const { getTrashedCouncilors, refetchTrashedData, getCurrentPageTrashed, setPageTrashed } = trashedCouncilors;
    const { getUnreadCouncilors, refetchUnreadData, getCurrentPageUnread,setPageUnread,getUnreadTabTitle} = unreadCouncilors;
    const [modalShow, setModalShow] = useState(false)
    const [windowType, setWindowType] = useState('upcoming');

    function changeWindowType(type) {
        setWindowType(type);
    }

    function changePage(newPage) {
        setPage(newPage)
    }

    //Councilor tab pagination
    const currentPage = getCurrentPage();
    const totalPages = getCouncilors().pages;

    function createPagination() {
        const pageItems = [];

        // Always show the first page
        pageItems.push(
            <Pagination.Item key={1} active={1 === currentPage} onClick={() => changePage(1)}>
                1
            </Pagination.Item>
        );

        // Show ellipsis after the first page if necessary
        if (currentPage > 3) {
            pageItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }

        // Show current page and next 2 pages (if they exist) dynamically
        const startPage = Math.max(2, currentPage - 1); // Start from current page or 2 if close to the start
        const endPage = Math.min(currentPage + 2, totalPages - 1); // Ensure no more than 2 pages after the current one

        for (let number = startPage; number <= endPage; number++) {
            pageItems.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => changePage(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        // Show ellipsis before the last page if necessary
        if (currentPage + 2 < totalPages - 1) {
            pageItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }

        // Always show the last page if there are more than one page
        if (totalPages > 1) {
            pageItems.push(
                <Pagination.Item key={totalPages} active={totalPages === currentPage} onClick={() => changePage(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return pageItems;
    }
    let paginatedItems = [];
    for (let number = 1; number <= getCouncilors().pages; number++) {
        paginatedItems.push(
            <Pagination.Item key={number} active={number === getCurrentPage()} onClick={() => changePage(number)}>
                {number}
            </Pagination.Item>,
        );
    }

//Trashed Councilor tab pagination
const currentTrashedPage = getCurrentPageTrashed();
const totalTrashedPages = getTrashedCouncilors().pages;

function createTrashedPagination() {
    const pageItems = [];

    // Always show the first page
    pageItems.push(
        <Pagination.Item key={1} active={1 === currentTrashedPage} onClick={() => setPageTrashed(1)}>
            1
        </Pagination.Item>
    );

    // Show ellipsis after the first page if necessary
    if (currentTrashedPage > 3) {
        pageItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    // Show current page and next 2 pages (if they exist) dynamically
    const startPage = Math.max(2, currentTrashedPage - 1); // Start from current page or 2 if close to the start
    const endPage = Math.min(currentTrashedPage + 2, totalTrashedPages - 1); // Ensure no more than 2 pages after the current one

    for (let number = startPage; number <= endPage; number++) {
        pageItems.push(
            <Pagination.Item key={number} active={number === currentTrashedPage} onClick={() => setPageTrashed(number)}>
                {number}
            </Pagination.Item>
        );
    }

    // Show ellipsis before the last page if necessary
    if (currentTrashedPage + 2 < totalTrashedPages - 1) {
        pageItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
    }

    // Always show the last page if there are more than one page
    if (totalTrashedPages > 1) {
        pageItems.push(
            <Pagination.Item key={totalTrashedPages} active={totalTrashedPages === currentTrashedPage} onClick={() => setPageTrashed(totalTrashedPages)}>
                {totalTrashedPages}
            </Pagination.Item>
        );
    }

    return pageItems;
}
let paginatedTrashedItems = [];
for (let number = 1; number <= getTrashedCouncilors().pages; number++) {
    paginatedTrashedItems.push(
        <Pagination.Item key={number} active={number === getCurrentPageTrashed()} onClick={() => setPageTrashed(number)}>
            {number}
        </Pagination.Item>,
    );
}    

//Unread Councilor tab pagination
const currentUnreadPage = getCurrentPageUnread();
const totalUnreadPages = getUnreadCouncilors().pages;

function createUnreadPagination() {
    const pageItems = [];

    // Always show the first page
    pageItems.push(
        <Pagination.Item key={1} active={1 === currentUnreadPage} onClick={() => setPageUnread(1)}>
            1
        </Pagination.Item>
    );

    // Show ellipsis after the first page if necessary
    if (currentUnreadPage > 3) {
        pageItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    // Show current page and next 2 pages (if they exist) dynamically
    const startPage = Math.max(2, currentUnreadPage - 1); // Start from current page or 2 if close to the start
    const endPage = Math.min(currentUnreadPage + 2, totalUnreadPages - 1); // Ensure no more than 2 pages after the current one

    for (let number = startPage; number <= endPage; number++) {
        pageItems.push(
            <Pagination.Item key={number} active={number === currentUnreadPage} onClick={() => setPageUnread(number)}>
                {number}
            </Pagination.Item>
        );
    }

    // Show ellipsis before the last page if necessary
    if (currentUnreadPage + 2 < totalUnreadPages - 1) {
        pageItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
    }

    // Always show the last page if there are more than one page
    if (totalUnreadPages > 1) {
        pageItems.push(
            <Pagination.Item key={totalUnreadPages} active={totalUnreadPages === currentUnreadPage} onClick={() => setPageUnread(totalUnreadPages)}>
                {totalUnreadPages}
            </Pagination.Item>
        );
    }

    return pageItems;
}
let paginatedUnreadItems = [];
for (let number = 1; number <= getUnreadCouncilors().pages; number++) {
    paginatedUnreadItems.push(
        <Pagination.Item key={number} active={number === getCurrentPageUnread()} onClick={() => setPageUnread(number)}>
            {number}
        </Pagination.Item>,
    );
}    

    function renderTable() {
        return (
            <section>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ width: '3%' }}>
                                Снимка
                            </th>
                            <th style={{ width: '31%' }}>
                                Съветник
                            </th>
                            <th style={{ width: '13%' }}>
                                Роля
                            </th>
                            <th style={{ width: '7%' }}>
                                Оценка
                            </th>
                            <th style={{ width: '31%' }}>
                                Време за отговор
                            </th>
                            <th style={{ width: '15%' }}>
                                Опции
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {getCouncilors().data.map((c) => (
                            <Councilor page={getCurrentPage()} roles={roles} key={c.id} data={c} />
                        ))}
                    </tbody>
                </Table>
                <div>
                    {totalPages > 1 ? (
                        <Pagination style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} centered>
                            {createPagination()}
                        </Pagination>
                    ) : null}
                </div>
            </section>
        )
    }
    function results() {
        return getCouncilors().pages > 0 ? renderTable() : <NoResults type={'councilors'} />
    }

    function renderTrashedTable() {
        return (
            <section>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ width: '3%' }}>
                                Снимка
                            </th>
                            <th style={{ width: '31%' }}>
                                Съветник
                            </th>
                            <th style={{ width: '13%' }}>
                                Роля
                            </th>
                            <th style={{ width: '7%' }}>
                                Оценка
                            </th>
                            <th style={{ width: '31%' }}>
                                Време за отговор
                            </th>
                            <th style={{ width: '15%' }}>
                                Опции
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {getTrashedCouncilors().data.map((c) => (
                            <TrashedCouncilor page={getCurrentPage()} roles={roles} key={c.id} data={c} />
                        ))}
                    </tbody>
                </Table>
                <div>
                    {totalTrashedPages > 1 ? (
                        <Pagination style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} centered>
                            {createTrashedPagination()}
                        </Pagination>
                    ) : null}
                </div>
            </section>
        )
    }
    function trashedResults() {
        return getTrashedCouncilors().pages > 0 ? renderTrashedTable() : <NoResults type={'councilors'} />
    }

    function renderUnreadTable() {
        return (
            <section>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ width: '3%' }}>
                                Снимка
                            </th>
                            <th style={{ width: '31%' }}>
                                Съветник
                            </th>
                            <th style={{ width: '13%' }}>
                                Роля
                            </th>
                            <th style={{ width: '7%' }}>
                                Оценка
                            </th>
                            <th style={{ width: '31%' }}>
                                Време за отговор
                            </th>
                            <th style={{ width: '15%' }}>
                                Опции
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {getUnreadCouncilors().data.map((c) => (
                            <UnreadCouncilor page={getCurrentPage()} roles={roles} key={c.id} data={c} />
                        ))}
                    </tbody>
                </Table>
                <div>
                    {totalUnreadPages > 1 ? (
                        <Pagination style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} centered>
                            {createUnreadPagination()}
                        </Pagination>
                    ) : null}
                </div>
            </section>
        )
    }
    function unreadResults() {
        return getUnreadCouncilors().pages > 0 ? renderUnreadTable() : <NoResults type={'unread'} />
    }

    function renderTabs() {
        return (
            <>
                <Tabs
                    defaultActiveKey="upcoming"
                    id="justify-tab-example"
                    className="mb-3"
                    transition
                    style={{ fontWeight: 'bold', marginTop: '20px', paddingLeft: '20%', paddingRight: '20%' }}
                    variant="pills"
                    justify
                    fill
                    onSelect={(eventKey) => changeWindowType(eventKey)}
                >
                    <Tab eventKey="upcoming" title="Настоящи общински съветници">
                        {councilors.loading == false ? results() : <Loader />}
                    </Tab>
                    <Tab eventKey="past" title="Съветници с преминал мандат" >
                        {trashedResults()}
                    </Tab>
                    <Tab eventKey="unread" title={getUnreadTabTitle()} >
                        {unreadResults()}
                    </Tab>
                </Tabs>
            </>
        )
    }


    return (
        <>
            <Container fluid="true" style={{ marginBottom: '25px' }}>
                <Row style={{ width: '100%' }}>
                    <Col style={{ marginLeft: '5px' }}>
                        <h1>Съветници</h1>
                    </Col>
                    <Col style={{ marginRight: '0px' }}>
                        <Button onClick={() => setModalShow(true)} variant="success" style={{ float: 'right', marginTop: '10px', marginRight: '0px' }} >
                            <img style={{ margin: '3px', marginRight: '6px' }} src={addImage} />Добави Съветник
                        </Button>
                    </Col>
                </Row>
            </Container>
            {renderTabs()}
            <AddEdit
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={null}
                roles={roles}
                type={'add'}
            />
        </>

    )
}
export default Councilors