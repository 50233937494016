export const ENDPOINTS = {
    LOGIN:'auth/councilor',
    COMMISSIONS: {
        GET: 'commission/get-admin',
        GET_ALL: 'commission/get-comms',
        FIND: 'commission/find',
        CREATE: 'commission/create',
        UPDATE: 'commission/update',
        DELETE: 'commission/delete',
        SET_LIVE: 'commission/live',
        ADD_COUNCILOR: 'commission/councilor/add',
        REMOVE_COUNCILOR: 'commission/councilor/remove'
    },
    COUNCILOR: {
        GET: 'councilor/get',
        GET_ALL: 'councilor/get-all',
        GET_TRASHED: 'councilor/get-thrashed',
        GET_UNREAD: 'councilor/get-unread',
        CREATE: 'councilor/create',
        UPDATE: 'councilor/update',
        DELETE: 'councilor/delete',
        PASSWORD: 'councilor/password',
        MESSAGES: 'councilor/messages',
        RESTORE: 'councilor/restore',
    },
    SESSIONS: {
        GET: 'session/get',
        GET_UPCOMING: 'session/get-upcoming',
        GET_PAST: 'session/get-past',
        CREATE: 'session/create',
        UPDATE: 'session/update',
        DOCUMENT: 'session/create-doc',
        DELETE: 'session/delete',
    },
    DOCUMENTS: {
        CREATE: 'documents/create',
        DELETE:'documents/delete',
        ORDER: 'documents/order',
    },
    ROLES: {
        GET: 'roles/get',
    },
    USER: {
        GET: 'user/get',
        CREATE: 'user/create',
        UPDATE: 'user/update-admin',
        DELETE: 'user/delete-admin',
    },
    
}