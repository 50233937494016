import { createContext, useContext, useState } from "react";
import useFetchCouncilors from "../hooks/useFetchCouncilors";

export const CouncilorsDataContext = createContext({})

const AllCouncilorsDataProvider = props => {
    const { getCouncilors, refetchData, loading } = useFetchCouncilors()
    
    const exportedData = {       
        getCouncilors,
        refetchData,
        loading
    }
    return <CouncilorsDataContext.Provider value={exportedData} {...props} />
}
export const useCouncilorsDataContext = () => useContext(CouncilorsDataContext)

export default AllCouncilorsDataProvider