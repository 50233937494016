import { useEffect, useState } from 'react';
import { CloseButton, Form, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useCreateEditSession from '../hooks/useCreateEditSession';
import documentImage from "../../../assets/images/app/pdfIcon.png";
import moment from 'moment';
import Draggable from './Draggable';
import { DndContext } from '@dnd-kit/core';
import Droppable from './Droppable';

function AddEditDocument(props) {
  const sessionId = props.data?.id;
  const [parent, setParent] = useState(null);
  const draggable = (
    <Draggable id="draggable">
      DRAG
    </Draggable>
  );
  const [formValues, setFormValues] = useState({
  });
  const [materialsFiles, setMaterialsFiles] = useState([]);
  const [materialsPreviews, setMaterialsPreviews] = useState([]);
  const [protocolsFiles, setProtocolsFiles] = useState([]);
  const [protocolsPreviews, setProtocolsPreviews] = useState([]);


  function resetForm() {
    setMaterialsFiles([]);
    setMaterialsPreviews([]);
    setProtocolsFiles([]);
    setProtocolsPreviews([]);
  }

  function handleDragEnd({over}) {
    setParent(over ? over.id : null);
  }

  const { handleSave, loading, validations } = useCreateEditSession(sessionId, props.onHide);

  const onFormSubmit = e => {
    e.preventDefault();
    const formData = new FormData();

    materialsFiles.forEach((file, index) => {
      formData.append(`materials[${index}]`, file);
    });

    protocolsFiles.forEach((file, index) => {
      formData.append(`protocols[${index}]`, file);
    });

    if (sessionId) {
      formData.append('id', sessionId);
    }

    handleSave(formData, resetForm, props.tab, null);
  };

  const handleRemoveMaterial = (indexToRemove) => {
    // Remove the selected file from the files array
    setMaterialsFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));

    // Remove the corresponding preview
    setMaterialsPreviews(prevPreviews => prevPreviews.filter((_, index) => index !== indexToRemove));
  };

  const handleRemoveProtocol = (indexToRemove) => {
    // Remove the selected file from the files array
    setProtocolsFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));

    // Remove the corresponding preview
    setProtocolsPreviews(prevPreviews => prevPreviews.filter((_, index) => index !== indexToRemove));
  };

  const handleChange = e => {
    const { name, files: selectedFiles } = e.target;
    const newFiles = Array.from(selectedFiles);

    if (name === 'materials') {
      setMaterialsFiles(prevFiles => [...prevFiles, ...newFiles]);

      const newPreviews = newFiles.map(file => URL.createObjectURL(file));
      setMaterialsPreviews(prevPreviews => [...prevPreviews, ...newPreviews]);

    } else if (name === 'protocols') {
      setProtocolsFiles(prevFiles => [...prevFiles, ...newFiles]);

      const newPreviews = newFiles.map(file => URL.createObjectURL(file));
      setProtocolsPreviews(prevPreviews => [...prevPreviews, ...newPreviews]);
    }
  };


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.data?.name ?? props.data?.event_date}
        </Modal.Title>
        <CloseButton onClick={props.onHide} variant='white' />
      </Modal.Header>
      <Modal.Body>
        <Form id='session-form' onSubmit={onFormSubmit}>
          <Form.Group className="mb-3" controlId="formBasicMaterials">
            <Form.Label><strong>Материали</strong></Form.Label>
            <br />
            <input
              id="fileInput"
              name="materials"
              multiple
              accept=".pdf, .doc, .docx, .xls, .xlsx, .zip, .rar"
              type="file"
              onChange={handleChange}
              style={{ display: 'none' }}
            />

            <label style={{ marginBottom: '10px' }} htmlFor="fileInput">
              <Button as="span" variant="primary" className='app-button'>
                Прикачете файлове
              </Button>
            </label>
            <Form.Text style={{ color: 'red' }}>
              {validations.materials}
            </Form.Text>
            <br />
            {materialsFiles && materialsFiles.map((file, index) => (
              <div key={index} style={{ position: 'relative', display: 'inline-block', margin: '5px' }}>
                <OverlayTrigger overlay={<Tooltip>{file.name}</Tooltip>} >
                  <div>
                    <Image style={{ maxHeight: '100px' }} src={documentImage} thumbnail />
                    <CloseButton
                      onClick={() => handleRemoveMaterial(index)}  // Remove file on click
                      className='close-button'
                    />
                  </div>
                </OverlayTrigger>
              </div>
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicProtocols">
            <Form.Label><strong>Протоколи</strong></Form.Label>
            <br />
            <input
              id="fileInput2"
              name="protocols"
              multiple
              type="file"
              onChange={handleChange}
              accept=".pdf, .doc, .docx, .xls, .xlsx, .zip, .rar"
              style={{ display: 'none' }}
            />
            <label style={{ marginBottom: '10px' }} htmlFor="fileInput2">
              <Button as="span" variant="primary" className='app-button'>
                Прикачете файлове
              </Button>
            </label>
            <Form.Text style={{ color: 'red' }}>
              {validations.protocols}
            </Form.Text>
            <br />
            {protocolsFiles && protocolsFiles.map((file, index) => (
              <div key={index} style={{ position: 'relative', display: 'inline-block', margin: '5px' }}>
                <OverlayTrigger overlay={<Tooltip>{file.name}</Tooltip>} >
                  <div>
                    <Image style={{ maxHeight: '100px' }} src={documentImage} thumbnail />
                    <CloseButton
                      onClick={() => handleRemoveProtocol(index)}  // Remove file on click
                      className='close-button'
                    />
                  </div>
                </OverlayTrigger>
              </div>
            ))}
          </Form.Group>
        </Form>
        {/* <DndContext onDragEnd={handleDragEnd}>
          {!parent ? draggable : null}
          <Droppable id="droppable">
            {parent === "droppable" ? draggable : 'Drop here'}
          </Droppable>
        </DndContext> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className='app-button' form='session-form' type="submit" disabled={loading}>
          Запази
        </Button>
        <Button variant='secondary' onClick={props.onHide}>Затвори</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEditDocument;
