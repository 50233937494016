import { Image, Nav, OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import { useEffect, useState } from "react"
import removeImage from "../../../assets/images/app/remove.svg"
import editImage from "../../../assets/images/app/edit.svg"
import AddEdit from "./AddEdit"
import Delete from "./Delete"
import Moment from "react-moment"
import appleIcon from "../../../assets/images/app/apple.svg"
import facebookIcon from "../../../assets/images/app/facebook.svg"
import googleIcon from "../../../assets/images/app/google.svg"
import mobileIcon from "../../../assets/images/app/phone.svg"


function User({data}) {
    const { name,lastname,email,address,number,full_name, created_at, apple_id, facebook_id, google_id } = data
    //const {name:roleName, id,abbr} = role
    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);

    function registrationMethod()
    {
        if(apple_id != null){
            return (
                <>
                 <OverlayTrigger overlay={<Tooltip>Apple</Tooltip>}>
                 <img src={appleIcon} />
                 </OverlayTrigger>
                </>                
            )
        }   
        else if(facebook_id !=  null) {
            return (
                <>
                 <OverlayTrigger overlay={<Tooltip>Facebook</Tooltip>}>
                 <img src={facebookIcon} />
                 </OverlayTrigger>
                </>
            )
        } 
        else if(google_id != null) {
            return (
                <>
                 <OverlayTrigger overlay={<Tooltip>Google</Tooltip>}>
                 <img src={googleIcon} />
                 </OverlayTrigger>
                </>
            )
        }   
        else {
            return (
                <>
                 <OverlayTrigger overlay={<Tooltip>Приложение</Tooltip>}>
                 <img src={mobileIcon} />
                 </OverlayTrigger>
                </>
            )
        } 
    }

    return (
        <>
            <tr>
                <td>
                    <strong>{full_name}</strong>
                </td>
                <td>
                    { email }
                </td>
                <td>
                    {number}
                </td>
                <td>
                    {address}
                </td>
                <td>
                 {registrationMethod()}
                </td>
                <td>
                    <Nav style={{ fontSize: '12px' }}>
                        {/* <Nav.Link onClick={() => setModalShow(true)}><Image style={{ filter: 'brightness(10%)'}} src={editImage} /></Nav.Link> */}
                        <Nav.Link onClick={() => setDeleteModalShow(true)}><Image src={removeImage} /></Nav.Link>
                    </Nav>
                </td>
            </tr>
            <AddEdit
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={data}
                type={'edit'}
            />
            <Delete
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                data={data}
                type={'delete'}
            />
        </>

    )
}
export default User