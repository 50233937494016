import { useEffect } from "react"
import useFetch from "../../../hooks/useFetch"
import { useFormDataContext } from "../../../providers/form"
import { useCommissionsDataContext } from "../providers/commissions"
import { ENDPOINTS } from "../../../constants/endpoints"

function useGoLive() {
    const commissionData = useCommissionsDataContext()

    const { fetch, loading, validations, handleValidations } = useFetch()

    async function handleSave(data) {
        if (loading) return
        //handleValidations().clear()

        const endpoint = ENDPOINTS.COMMISSIONS.SET_LIVE
        const method = "post"

        try {
            const response = await fetch({
                endpoint,
                method,
                data,
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            if (response.status === 200) {
                commissionData.refetchData(commissionData.getCurrentPage())
            }
            else {
                console.log("Response with error status:", response.status);
            }
        } catch (error) {
            console.log("An error occured: ", error)
        }

    }

    return {
        loading,
        handleSave,
        validations,
        handleValidations
    }
}
export default useGoLive
