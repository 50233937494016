import useFetch from "../../../hooks/useFetch"
import { ENDPOINTS } from "../../../constants/endpoints"
import { useCouncilorsDataContext } from "../providers/councilors"
import { useToast } from "../../app/components/ToastContext"
import { useTrashedCouncilorsDataContext } from "../providers/trashedCouncilors"

function useDeleteCouncilor(councilorId = null) {
    const {successToast} = useToast()
    const councilorData = useCouncilorsDataContext()
    const trashedCouncilorData = useTrashedCouncilorsDataContext()

    const {fetch,loading} = useFetch()

    function handleSave(data) {
        if (loading) return

        const endpoint = ENDPOINTS.COUNCILOR.DELETE
        const method = "post"
        
        fetch({
            endpoint,
            method,
            data,
        })
        .then(() => {
            councilorData.refetchData(councilorData.getCurrentPage())
            trashedCouncilorData.refetchTrashedData(trashedCouncilorData.getCurrentPageTrashed());
            successToast('Успешно премахнахте съветник')
        })
    }

    return {
        loading,
        handleSave,
    
    }
}
export default useDeleteCouncilor
