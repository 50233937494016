import { useEffect, useState } from "react"
import { ENDPOINTS } from "../../../constants/endpoints"
import useFetch from "../../../hooks/useFetch"

function useFetchCouncilors() {
    const {fetch,loading} = useFetch()

    const [councilors,setCouncilors] = useState([])
    useEffect(() => {
        _fetchData()
    }, [])
    
    function _fetchData() {
        fetch({endpoint: ENDPOINTS.COUNCILOR.GET_ALL,method:'get'})
        .then(res => {
            setCouncilors(res.data)
        })
    }

    function getCouncilors() {
        return councilors
    }

    function refetchData(page) {
        _fetchData(page)
    }

    return {
        getCouncilors,
        refetchData,
        loading
    }
}
export default useFetchCouncilors