import { useEffect, useState } from "react"
import Commission from "./components/Commission"
import useFetchCommissions from "./hooks/useFetchCommissions"
import { useCommissionsDataContext } from "./providers/commissions"
import { Button, Col, Container, Image, Pagination, Row, Spinner, Table } from "react-bootstrap"
import NoResults from "../app/components/NoResults"
import AddEdit from "./components/AddEdit"
import Loader from "../app/components/Loader"
import addImage from "../../assets/images/app/add.svg"
import { useAuth } from "../../providers/authenticaion"

function Commissions() {
    const commissions = useCommissionsDataContext()
    const { getCommissions, refetchData, getCurrentPage, setPage, loading } = commissions
    const [modalShow, setModalShow] = useState(false);

    function changePage(newPage) {
        setPage(newPage);
    }

    const currentPage = parseInt(getCurrentPage());
    const totalPages = parseInt(getCommissions().pages);

    function createPagination() {
        const pageItems = [];
        
        // Always show the first page
        pageItems.push(
            <Pagination.Item key={1} active={1 === currentPage} onClick={() => changePage(1)}>
                1
            </Pagination.Item>
        );

        // Show ellipsis after the first page if necessary
        if (currentPage > 3) {
            pageItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }

        // Show current page and next 2 pages (if they exist) dynamically
        const startPage = Math.max(2, currentPage-1); // Start from current page or 2 if close to the start
        const endPage = Math.min(currentPage + 2, totalPages - 1); // Ensure no more than 2 pages after the current one
        
        for (let number = startPage; number <= endPage; number++) {
            pageItems.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => changePage(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        // Show ellipsis before the last page if necessary
        if (currentPage + 2 < totalPages - 1) {
            pageItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }

        // Always show the last page if there are more than one page
        if (totalPages > 1) {
            pageItems.push(
                <Pagination.Item key={totalPages} active={totalPages === currentPage} onClick={() => changePage(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return pageItems;
    }

    function renderTable() {
        return (
            <section id="commission-table">
                <Container fluid="true" style={{marginBottom: '25px'}}>
                    <Row style={{width:'100%'}}>
                        <Col style={{marginLeft: '5px'}}>
                            <h1>Комисии</h1>
                        </Col>
                        <Col style={{marginRight: '0px'}}>
                            <Button variant="success" style={{float: 'right', marginTop: '10px'}} onClick={() => setModalShow(true)}>
                                <img style={{margin: '3px', marginRight: '6px'}} src={addImage}/>Добави комисия
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{width: '3%'}}>Снимка</th>
                            <th style={{width: '57%'}}>Комисия</th>
                            <th style={{width: '15%'}}>Създадена на</th>
                            <th style={{width: '15%'}}>Последна редакция</th>
                            <th style={{width: '10%'}}>Опции</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getCommissions()?.data.map((c) => (
                            <Commission page={currentPage} key={c.id} data={c}/>
                        ))}
                    </tbody>
                </Table>
                <div>
                    {totalPages > 1 ? (
                        <Pagination style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} centered>
                            {createPagination()}
                        </Pagination>
                    ) : null}
                </div>
            </section>
        );
    }

    function results() {
        return totalPages > 0 ? renderTable() : <NoResults />;
    }

    return (
        <>
            {!commissions.loading ? results() : <Loader/>}
            <AddEdit show={modalShow} onHide={() => setModalShow(false)} data={null} type={'add'} />
        </>
    );
}

export default Commissions;

