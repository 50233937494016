import useFetch from "../../../hooks/useFetch";
import { ENDPOINTS } from "../../../constants/endpoints";
import { useToast } from "../../app/components/ToastContext";

function useOrderFiles() {
    const { fetch, loading, validations, handleValidations } = useFetch();
    const {successToast} = useToast()

    async function handleSave(data) {
        let auth = localStorage.getItem('site');
        const token = auth;
        if (loading) return;
        handleValidations().clear();

        const endpoint = ENDPOINTS.DOCUMENTS.ORDER;
        const method = "post";

        try {
            const response = await fetch({
                endpoint,
                method,
                data,
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                successToast('Успешно запазихте промените')
            } else {
                console.error('Request failed with status:', response.status);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    return {
        loading,
        handleSave,
        validations,
        handleValidations
    };
}

export default useOrderFiles;
