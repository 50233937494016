import { useEffect, useState } from "react"
import { ENDPOINTS } from "../../../constants/endpoints"
import useFetch from "../../../hooks/useFetch"

function useFetchCouncilors() {
    const {fetch,loading} = useFetch()

    const [councilors,setCouncilors] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    useEffect(() => {
        _fetchData(currentPage)
    }, [currentPage])
    
    function _fetchData() {
        fetch({endpoint: `${ENDPOINTS.COUNCILOR.GET}?page=${currentPage}`,method:'get'})
        .then(res => {
            setCouncilors(res.data)
        })
    }

    function getCouncilors() {
        return councilors
    }

    function getCurrentPage() {
        return currentPage
    }

    function setPage(page) {
        setCurrentPage(page)
    }

    function refetchData(page) {
        _fetchData(page)
    }

    return {
        getCouncilors,
        refetchData,
        getCurrentPage,
        setPage,
        loading
    }
}
export default useFetchCouncilors