import { useEffect, useState } from 'react';
import { Alert, CloseButton, Dropdown, DropdownButton, Form, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useCreateEditCouncilor from '../hooks/useCreateEditCouncilor';
import useCreateEditCommission from '../../commissions/hooks/useCreateEditCommission';
import { useRolesDataContext } from '../providers/roles';
import useFetchRoles from '../hooks/useFetchRoles';
import useEditPassword from '../hooks/useEditPassword';

function EditPassword(props) {
    const councilorId = props.data?.id;
    const [selected, setSelected] = useState({ eventKey: null, value: 'Член' });


    const [formValues, setFormValues] = useState({
        old_password: null,
        password: null,
        confirm_password: null,
    });
    const [file, setFile] = useState(props.data?.path || null);
    const [image, setImage] = useState(null);

    const { handleSave, loading, validations, handleValidations } = useEditPassword(councilorId, props.onHide);

    function validate(fieldName) {
        return handleValidations().validate(fieldName)
    }

    function resetForm() {
        setFormValues({
            old_password: null,
            password: null,
            confirm_password: null,
        })
    }

    const onFormSubmit = e => {
        e.preventDefault();
        const formData = new FormData();

        formData.append('old_password', formValues.old_password);
        formData.append('password', formValues.password);
        formData.append('confirm_password', formValues.confirm_password);
        if (councilorId) {
            formData.append('id', councilorId);
        }

        handleSave(formData, resetForm);
    };

    const handleChange = e => {
        const { name, value, type, checked, files } = e.target;

        setFormValues(prevValues => ({
            ...prevValues,
            [name]: type === 'checkbox' ? checked : value,
        }));

    };


    const handleSelect = (eventKey, event) => {
        setSelected({ eventKey: event.target.id, value: eventKey });
        setFormValues(prevValues => ({
            ...prevValues,
            role_id: event.target.id,
        }));
    };



    useEffect(() => {
        if (props.roles?.length > 0) {
            const matchedRole = props.roles?.find(role => role.id === formValues.role_id);
            if (matchedRole) {
                setSelected({ eventKey: matchedRole.id, value: matchedRole.name });
            } else {
                setSelected({ eventKey: null, value: 'Член' });
            }
        }
    }, [props.roles]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редакция на парола
                </Modal.Title>
                <CloseButton onClick={props.onHide} variant='white' />
            </Modal.Header>
            <div className="sticky-container">
                <Modal.Body>
                    <Form id='councilor-form' onSubmit={onFormSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label><strong>Парола</strong></Form.Label>
                            <Form.Control
                                name='old_password'
                                type="password"
                                placeholder="Въведете парола"
                                value={formValues.old_password}
                                onChange={handleChange}
                            />
                            {validate("old_password").message ? (
                                <>
                                    <Form.Text style={{ color: 'red' }}>{validate("old_password").message}</Form.Text>
                                    <br />
                                </>
                            ) : (
                                <></>
                            )}                            
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label><strong>Нова парола</strong></Form.Label>
                            <Form.Control
                                name='password'
                                type="password"
                                placeholder="Въведете парола"
                                value={formValues.password}
                                onChange={handleChange}
                            />
                            {validate("password").message ? (
                                <>
                                    <Form.Text style={{ color: 'red' }}>{validate("password").message}</Form.Text>
                                    <br />
                                </>
                            ) : (
                                <></>
                            )}
                            {validate("confirm_password").message ? (
                                <Form.Text style={{ color: 'red' }}>{validate("confirm_password").message}</Form.Text>
                            ) : (
                                <></>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label><strong>Повторете новата парола</strong></Form.Label>
                            <Form.Control
                                name='confirm_password'
                                type="password"
                                placeholder="Повторете парола"
                                value={formValues.confirm_password}
                                onChange={handleChange}
                            />
                            {validate("password").message ? (
                                <>
                                    <Form.Text style={{ color: 'red' }}>{validate("password").message}</Form.Text>
                                    <br />
                                </>
                            ) : (
                                <></>
                            )}
                            {validate("confirm_password").message ? (
                                <Form.Text style={{ color: 'red' }}>{validate("confirm_password").message}</Form.Text>
                            ) : (
                                <></>
                            )}
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </div>
            <Modal.Footer className='sticky-div'>
                <Button variant="primary" className='app-button' form='councilor-form' type="submit" disabled={loading}>
                    {props.type === 'edit' ? 'Запази' : 'Добави'}
                </Button>
                <Button variant='secondary' onClick={props.onHide}>Затвори</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditPassword;
