import { useEffect, useState } from 'react';
import { CloseButton, Form, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useGoLive from '../hooks/useGoLive';
import Moment from 'react-moment';

function Live(props) {
    const commissionId = props.data?.id;

    // Set the initial live session or default to 0
    const initialLiveSession = props.data?.sessions?.find(s => s.session?.live)?.session?.id || 0;

    const [formValues, setFormValues] = useState({
        live: props.data?.live || 0,
        live_url: props.data?.live_id ? "https://www.youtube.com/watch?v=" + props.data?.live_id : "" || "",
        commission_id: props.data?.id || null,
        session_id: initialLiveSession, // Initialize with the live session
    });

    const { handleSave, loading, validations, handleValidations } = useGoLive(props.page);

    function validate(fieldName) {
        return handleValidations().validate(fieldName);
    }

    const onFormSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('live_url', formValues.live_url);
        formData.append('live', Number(formValues.live));
        formData.append('session_id', formValues.session_id);
        if (commissionId) {
            formData.append('commission_id', commissionId);
        }
        handleSave(formData);
    };

    const handleChange = e => {
        const { name, value, type, checked } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: type === 'checkbox' ? checked : value,
        }));

        // Update the session_id when a radio button is clicked
        if (name === "session_id") {
            setFormValues(prevValues => ({
                ...prevValues,
                session_id: parseInt(value), // Make sure the session_id is updated correctly
            }));
        }

        if (name === 'live_url' && value != null) {
            setFormValues(prevValues => ({
                ...prevValues,
                live: true,
            }));
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h6>
                        {props.data.name}
                    </h6>
                </Modal.Title>
                <CloseButton onClick={props.onHide} variant='white' />
            </Modal.Header>
            <Modal.Body>
                <Form id='commission-form' onSubmit={onFormSubmit}>
                    {/* <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label><strong>Линк към заседание</strong></Form.Label>
                        <Form.Control
                            name='live_url'
                            type="text"
                            placeholder="e.g. https://www.youtube.com/watch?v=AjWfY7SnMBI"
                            value={formValues.live_url}
                            onChange={handleChange}
                        />
                        {validate("live_url").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("live_url").message}</Form.Text>
                        ) : (
                            <></>
                        )}
                    </Form.Group> */}
                     <Form.Group className="mb-3" controlId="formBasicName">
                        {props.data?.sessions && props.data.sessions.length > 0 ? (
                            props.data.sessions.map((s) => (
                                s.session ? (
                                    s.session.live == 1 ? <span><b>В момента е на живо заседание "{s.session.name ?? s.session.event_date}" </b></span> : <></>
                                ) : null
                            ))
                        ) : (
                            <>
                                <Form.Text style={{ color: 'red', fontWeight: 'bolder' }}>
                                    Тази комисия няма заседания
                                </Form.Text>
                                <br />
                            </>
                        )}

                        {/* <Form.Check
                            checked={formValues.session_id === s.session?.id} // Only rely on session_id for checked state
                            inline
                            label={s.session?.name ?? <Moment format="DD/MM/YYYY HH:mm">{s.session?.event_date}</Moment> ?? ''}
                            name="session_id"
                            value={s.session?.id} // Use the session id as the value
                            type="radio"
                            id={`inline-radio-${s.session?.id}`}
                            onChange={handleChange} // Ensure this updates the session_id
                        /> */}

                        {validate("session_id").message ? (
                            <>
                                <br />
                                <Form.Text style={{ color: 'red' }}>{validate("session_id").message}</Form.Text>
                            </>
                        ) : (
                            <></>
                        )}

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicLive">
                        <Form.Check
                            name='live'
                            type="switch"
                            label="На живо"
                            checked={formValues.live}
                            onChange={handleChange}
                        />
                        {validate("live").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("live").message}</Form.Text>
                        ) : (
                            <></>
                        )}
                    </Form.Group>                   
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className='app-button' form='commission-form' type="submit" disabled={loading}>
                    Запази
                </Button>
                <Button variant='secondary' onClick={props.onHide}>Затвори</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Live;
