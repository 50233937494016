import { useEffect, useState } from "react";
import { Image, Nav, OverlayTrigger, Tooltip } from "react-bootstrap"
import Moment from "react-moment"
import liveImage from "../../../assets/images/app/live.png"
import removeImage from "../../../assets/images/app/remove.svg"
import editImage from "../../../assets/images/app/edit.svg"
import docImage from "../../../assets/images/app/docIcon.png"
import 'moment/locale/bg'
import AddEdit from "./AddEdit";
import AddEditDocument from "./AddEditDocuments";
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup'
import Documents from "./Documents";
import goLiveImage from "../../../assets/images/app/goLive.png"
import Live from "./Live";
import Delete from "./Delete";
import moment from "moment";

function Session({ data, tab }) {
  const { id, name, created_at, updated_at, event_date, materials_count, protocols_count, materials, commissions, live, commission_name, commission_id } = data
  const [modalShow, setModalShow] = useState(false);
  const [documentType, setDocumentType] = useState();
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [documentsModalShow, setDocumentsModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [liveModalShow, setLiveModalShow] = useState(false);


  function OpenModal(type) {
    setDocumentType(type)
    setDocumentsModalShow(true)
  }
  return (
    <>
      <tr>
        <td>
          <Moment format="DD/MM/YYYY HH:mm">{event_date}</Moment>
        </td>
        <td>
          {name ?? <Moment format="Do MMMM YYYY, HH:mm" locale="bg">{event_date}</Moment>} {live === 1 ? <Image src={liveImage} height={25} /> : <></>}
        </td>
        <td>
          {commission_id == 696969 ? 'Няма' : commission_name ?? 'Няма'}
        </td>
        <td>
          <Nav.Link onClick={() => OpenModal('materials')}>
            Материали
            <Badge style={{ marginLeft: '5px' }} bg="primary" pill>
              {materials_count}
            </Badge>
          </Nav.Link>
        </td>
        <td>
          <Nav.Link onClick={() => OpenModal('protocols')}>
            Протоколи
            <Badge style={{ marginLeft: '5px' }} className="badge" bg="primary" pill>
              {protocols_count}
            </Badge>
          </Nav.Link>
        </td>
        <td>
          <Moment format="DD/MM/YYYY HH:mm" locale="bg">{created_at}</Moment>
        </td>
        <td>
          <Nav>
            <Nav.Link
              //disabled={tab === 'past'}
              onClick={() => commission_id ? (tab != 'past' ? setLiveModalShow(true) : null) : false}
            >
              <OverlayTrigger overlay={commission_id ? (tab === 'past' ? <Tooltip>Това заседание е преминало</Tooltip> : <></>) : <Tooltip>Това заседание няма комисия</Tooltip>} >
                <Image
                  src={goLiveImage}
                  style={{ filter: commission_id ? (tab === 'past' ? (live == 1 ? 'none' : 'grayscale(100%)') : 'none') : 'grayscale(100%)' }}
                />
              </OverlayTrigger>
            </Nav.Link>

            <Nav.Link onClick={() => setDocumentModalShow(true)}><Image style={{ height: '70%', marginRight: '-10px' }} src={docImage} /></Nav.Link>
            <Nav.Link onClick={() => tab == 'past' ? null : (live == 1 ? null : setModalShow(true))}>
              <OverlayTrigger overlay={tab === 'past' ? <Tooltip>Това заседание е преминало</Tooltip> : live == 1 ? <Tooltip>Това заседание е на живо</Tooltip> : <></>}>
                <Image
                  src={editImage}
                  style={{ filter: commission_id ? (tab === 'past' ? 'none' : (live == 1 ? 'none' : 'brightness(10%)')) : 'none' }}
                />
              </OverlayTrigger>
            </Nav.Link>
            <Nav.Link onClick={() => setDeleteModalShow(true)} ><Image src={removeImage} /></Nav.Link>
          </Nav>
        </td>
      </tr>
      <AddEdit
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
        tab={tab}
        type={'edit'}
      />
      <AddEditDocument
        show={documentModalShow}
        onHide={() => setDocumentModalShow(false)}
        data={data}
        tab={tab}
        type={'edit'}
      />
      <Documents
        show={documentsModalShow}
        onHide={() => setDocumentsModalShow(false)}
        data={data}
        tab={tab}
        type={documentType}
      />
      <Delete
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        data={data}
        tab={tab}
        type={'delete'}
      />
      <Live
        show={liveModalShow}
        onHide={() => setLiveModalShow(false)}
        data={data}
        tab={tab}
        type={'live'}
      />
    </>
  )
}
export default Session