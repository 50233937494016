import { useEffect } from "react"
import useFetch from "../../../hooks/useFetch"
import { ENDPOINTS } from "../../../constants/endpoints"
import { useSessionsDataContext } from "../providers/sessions"
import { usePastSessionsDataContext } from "../providers/pastSessions";
import { useUpcomingSessionsDataContext } from "../providers/upcomingSessions";
import { useToast } from "../../app/components/ToastContext";

function useDeleteDocument(commissionId = null) {
    const sessionData = useSessionsDataContext();
    const pastSessionData = usePastSessionsDataContext();
    const upcomingSessionData = useUpcomingSessionsDataContext();
    const { successToast } = useToast();

    const {fetch,loading} = useFetch()

    function handleSave(data,type,onHide) {
        if (loading) return

        const endpoint = ENDPOINTS.SESSIONS.DELETE
        const method = "post"
        
        fetch({
            endpoint,
            method,
            data,
        })
        .then(() => {
            //sessionData.refetchData(sessionData.getCurrentPage())
            if(type === 'upcoming') upcomingSessionData.refetchDataUpcoming(upcomingSessionData.getCurrentPageUpcoming());
            if(type === 'past') pastSessionData.refetchDataPast(pastSessionData.getCurrentPagePast());
            successToast('Успешно изтрихте заседание')
            onHide()
        })
    }

    return {
        loading,
        handleSave,
    
    }
}
export default useDeleteDocument
