import { useState } from "react";
import api from "../helpers/api";
import useIsLoading from "./useIsLoading";
import useValidation from "./useValidations";

function useFetch() {
    const [errValidations,setErrValidations] = useState([])

    const {
        loading,
        setLoading
    } = useIsLoading()
    const {
        handleValidations,
        validations,
    } = useValidation()
    
    async function fetch(params) {
        const {endpoint,method,data} = params

        handleValidations().clear()
        setLoading(true)
        return await api[method](endpoint,data || {})
        .then(res => {
            return res
        })
        .catch(err => {
            const {status, data} = err.response
            if(data.errors) handleValidations().set().fields(data.errors)

            //throw err
        }).finally(() => {
            setLoading(false)
        })
    }

    return {
        fetch,
        validations,
        handleValidations,
        errValidations,
        loading
    }
}

export default useFetch