import { createContext, useContext, useState } from "react";
import useFetchSessions from "../hooks/useFetchSessions";
import useFetchPastSessions from "../hooks/useFetchPastSessions";

export const PastSessionsDataContext = createContext({})

const PastSessionsDataProvider = props => {
    const { getPastSessions, getCurrentPagePast, refetchDataPast, setPagePast, loading } = useFetchPastSessions()

    const exportedData = {
        getPastSessions,
        getCurrentPagePast,
        refetchDataPast,
        setPagePast,
        loading
    }
    return <PastSessionsDataContext.Provider value={exportedData} {...props} />
}
export const usePastSessionsDataContext = () => useContext(PastSessionsDataContext)

export default PastSessionsDataProvider