import moment from "moment"
import { useEffect, useState } from "react";
import { Image, Nav, OverlayTrigger, Tooltip } from "react-bootstrap"
import Moment from "react-moment"
import AddEdit from "./AddEdit";
import liveImage from "../../../assets/images/app/live.png"
import removeImage from "../../../assets/images/app/remove.svg"
import editImage from "../../../assets/images/app/edit.svg"
import goLiveImage from "../../../assets/images/app/goLive.png"
import Delete from "./Delete";
import Live from "./Live";
function Commission({ data }) {
    const { id, name, path, path2, live, created_at, updated_at, sessions, councilors } = data
    const [modalShow, setModalShow] = useState(false);
    const [liveModalShow, setLiveModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    return (
        <>
            <tr>
                <td>
                    <Image src={path} height={50} width={50} />
                </td>
                <td>
                    <Nav.Link onClick={() => setModalShow(true)}><b>{name}</b> {live === 1 ? <Image src={liveImage} height={25} /> : <></>}</Nav.Link>
                </td>
                <td>
                    <Moment format="DD/MM/YYYY HH:mm">{created_at}</Moment>
                </td>
                <td>
                    <Moment format="DD/MM/YYYY HH:mm">{updated_at}</Moment>
                </td>
                <td>
                    <Nav style={{ fontSize: '12px' }}>
                        <Nav.Link onClick={() => live == 1 ? setLiveModalShow(true) : null}>
                            <OverlayTrigger overlay={live == 1 ? <></> : <Tooltip>Тази комисия не е на живо</Tooltip>}>
                                <Image style={{ filter: (live == 1 ? 'none' : 'grayscale(100%)') }} src={goLiveImage} />
                            </OverlayTrigger>
                        </Nav.Link>
                        <Nav.Link onClick={() => setModalShow(true)}><Image style={{ filter: 'brightness(10%)'}} src={editImage} /></Nav.Link>
                        <Nav.Link onClick={() => setDeleteModalShow(true)}><Image src={removeImage} /></Nav.Link>
                    </Nav>
                </td>
            </tr>
            <AddEdit
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={data}
                type={'edit'}
            />
            <Delete
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                data={data}
                type={'delete'}
            />
            <Live
                show={liveModalShow}
                onHide={() => setLiveModalShow(false)}
                data={data}
                type={'live'}
            />
        </>
    )
}
export default Commission