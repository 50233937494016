import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, Button, Form, Image, FormLabel } from 'react-bootstrap';
import { useCouncilorsDataContext } from '../providers/councilors';
import clearIcon from "../../../assets/images/app/x-lg.svg";

const DualListBox = ({ preSelectedItems, onSelectionChange, onViceChairmanChange, onChairmanChange }) => {
  const councilors = useCouncilorsDataContext();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedChairman, setSelectedChairman] = useState({});
  const [selectedViceChairmen, setSelectedViceChairmen] = useState([]);
  const [filter, setFilter] = useState('');
  const [chairmanFilter, setChairmanFilter] = useState('');
  const [viceChairmanFilter, setViceChairmanFilter] = useState('');

  const preSelectedIds = preSelectedItems?.map(item => item.councilor_id);

  useEffect(() => {
    preSelectedItems?.forEach(item => {
      if (item.role_id === 1) setSelectedChairman(item.user);
      if (item.role_id === 2) setSelectedViceChairmen(prev => [...prev, item.user]);
    });
    const initiallySelected = councilors.getCouncilors()?.data?.filter(item =>
      preSelectedIds?.includes(item.id)
    );
    setSelectedItems(initiallySelected);
  }, [councilors]);

  useEffect(() => {
    const selectedIds = selectedItems?.map(item => item.id);
    onSelectionChange(selectedIds);
  }, [selectedItems]);

  useEffect(() => {
    onChairmanChange(selectedChairman.id);
  }, [selectedChairman]);

  useEffect(() => {
    onViceChairmanChange(selectedViceChairmen.map(vc => vc.id));
  }, [selectedViceChairmen]);

  const filteredItems = councilors.getCouncilors()?.data?.filter(
    item =>
      !selectedItems.some(selectedItem => selectedItem.id === item.id) &&
      item.name.toLowerCase().includes(filter.toLowerCase())
  );

  const filteredChairmanItems = councilors.getCouncilors()?.data?.filter(
    item => (
      selectedItems.some(selectedItem => selectedItem.id === item.id) &&
      (chairmanFilter ? item.name.toLowerCase().includes(chairmanFilter.toLowerCase()) : item.id === selectedChairman.id)
    )
  );

  const filteredViceChairmanItems = councilors.getCouncilors()?.data?.filter(
    item => (
      selectedItems.some(selectedItem => selectedItem.id === item.id) &&
      (viceChairmanFilter ? item.name.toLowerCase().includes(viceChairmanFilter.toLowerCase()) : selectedViceChairmen.some(vc => vc.id === item.id))
    )
  );

  const handleSelect = (item) => {
    if (!selectedItems.some(selectedItem => selectedItem.id === item.id)) {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleRemove = (item) => {
    setSelectedItems(selectedItems?.filter(i => i.id !== item.id));
    if (item.id === selectedChairman.id) setSelectedChairman({});
    setSelectedViceChairmen(prev => prev.filter(vc => vc.id !== item.id));
  };

  const handleChairman = (item) => {
    setSelectedChairman(item);
  };

  const handleViceChairman = (item) => {
    if (selectedViceChairmen.some(vc => vc.id === item.id)) {
      setSelectedViceChairmen(prev => prev.filter(vc => vc.id !== item.id));
    } else {
      setSelectedViceChairmen(prev => [...prev, item]);
    }
  };

  return (
    <Container style={{ paddingLeft: '0px', border: 'none', paddingRight:'0px' }}>
      <Form.Control
        type="text"
        placeholder="Търсене по име"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        className="mb-3"
      />
      <Row>
        <Col>
          <span><strong>Списък със съветници</strong></span>
          <ListGroup className='councilor-picker-box' style={{ border: 'solid', borderColor: '#C1C1C1', borderWidth: '1px' }}>
            {filteredItems?.map((item) => (
              <ListGroup.Item key={item.id} action onClick={() => handleSelect(item)} className='add-councilor-button'>
                {item.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col>
          <span><strong>Избрани съветници</strong></span>
          {selectedItems?.length > 0 ?
            <a style={{ display: 'flex', alignItems: 'center', float: 'right', color: 'red', cursor: 'pointer' }} onClick={() => setSelectedItems([])}>
              <Image style={{ marginRight: '5px', filter: 'brightness(0) saturate(100%) invert(33%) sepia(92%) saturate(3814%) hue-rotate(340deg) brightness(104%) contrast(101%)' }} src={clearIcon} />
              Изчисти
            </a>
            : <></>}
          <ListGroup className='councilor-picker-box' style={{ border: 'solid', borderColor: '#C1C1C1', borderWidth: '1px' }}>
            {selectedItems?.map((item) => (
              <ListGroup.Item key={item.id} action onClick={() => handleRemove(item)} className='remove-councilor-button'>
                {item.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
      {selectedItems?.length > 0 ?
        <Row>
          <FormLabel style={{ marginTop: '15px' }}><strong>Изберете председател</strong></FormLabel>
          <Form>
            <Form.Control
              type="text"
              placeholder="Търсене по име"
              value={chairmanFilter}
              onChange={(e) => setChairmanFilter(e.target.value)}
              className="mb-3"
            />
            <ListGroup>
              {chairmanFilter != null ? filteredChairmanItems.map((item) => (
                <ListGroup.Item
                  key={item.id}
                  onClick={() => {
                    if (selectedChairman.id !== item.id) {
                      handleChairman(item);
                    }
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {item.name}
                  <Form.Check.Input
                    style={{ float: 'right' }}
                    name="chairman"
                    type="radio"
                    onChange={() => handleChairman(item)}
                    checked={selectedChairman.id === item.id ? true : undefined}
                    isValid
                  />
                </ListGroup.Item>
              )) : <></>}
            </ListGroup>
          </Form>
          <FormLabel style={{ marginTop: '15px' }}><strong>Изберете заместник-председатели</strong></FormLabel>
          <Form>
            <Form.Control
              type="text"
              placeholder="Търсене по име"
              value={viceChairmanFilter}
              onChange={(e) => setViceChairmanFilter(e.target.value)}
              className="mb-3"
            />
            <ListGroup>
              {viceChairmanFilter != null ? filteredViceChairmanItems.map((item) => (
                <ListGroup.Item
                  key={item.id}
                  onClick={() => {
                    handleViceChairman(item);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {item.name}
                  <Form.Check.Input
                    style={{ float: 'right' }}
                    name="viceChairman"
                    type="checkbox"
                    isValid
                    onChange={() => handleViceChairman(item)}
                    disabled={selectedChairman.id === item.id}
                    checked={selectedChairman.id === item.id ? false : selectedViceChairmen.some(vc => vc.id === item.id)}
                  />
                </ListGroup.Item>
              )) : <></>}
            </ListGroup>
          </Form>
        </Row>
        : <></>
      }
    </Container>
  );
};

export default DualListBox;
