import { Button, Container, Image, Nav, Navbar } from "react-bootstrap"
import logoImage from "../../../assets/images/header/logoIcon.svg"
import "../../../assets/scss/app.scss"
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../providers/authenticaion";

function Header() {
  const { pathname } = useLocation()
  const auth = useAuth();
  return (

    <>
      {pathname != '/login' && auth.token ?
        <Navbar expanded className="header-navbar" bg="dark" data-bs-theme="dark">
          <Container fluid >
            <Navbar.Brand href="/"><Image src={logoImage} height={30} />Великотърновски общински съвет</Navbar.Brand>
            <Nav navbar className="me-auto">
              <Nav.Link eventKey="commissions" active={pathname == '/commissions'} href="/commissions">Комисии</Nav.Link>
              <Nav.Link eventKey="councilors" active={pathname == '/councilors'} href="/councilors">Съветници</Nav.Link>
              <Nav.Link eventKey="sessions" active={pathname == '/sessions'} href="/sessions">График</Nav.Link>
              <Nav.Link eventKey="users" active={pathname == '/users'} href="/users">Потребители</Nav.Link>
            </Nav>
            <Nav navbar className="justify-content-end">
              <Nav.Link onClick={() => auth.logOut()} className="btn-submit">
                Изход
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
        :
        <></>
      }
    </>
  );
}
export default Header