import { useEffect, useState } from "react"
import { ENDPOINTS } from "../../../constants/endpoints"
import useFetch from "../../../hooks/useFetch"

function useFetchUsers() {
    const {fetch,loading} = useFetch()

    const [users,setUsers] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    useEffect(() => {
        _fetchData(currentPage)
    }, [currentPage])
    
    function _fetchData(refetch = false) {
        fetch({endpoint: `${ENDPOINTS.USER.GET}?page=${currentPage}`,method:'get'})
        .then(res => {
            setUsers(res.data)
            if(refetch == true) setPage(res.data.page)
        })
    }

    function getUsers() {
        return users
    }

    function getCurrentPage() {
        return currentPage
    }

    function setPage(page) {
        setCurrentPage(page)
    }

    function refetchData(page) {
        _fetchData(page,true)
    }

    return {
        getUsers,
        refetchData,
        getCurrentPage,
        setPage,
        loading
    }
}
export default useFetchUsers