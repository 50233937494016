import { useEffect, useState } from 'react';
import { Alert, CloseButton, Dropdown, DropdownButton, Form, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useCreateEditCouncilor from '../hooks/useCreateEditCouncilor';
import useCreateEditCommission from '../../commissions/hooks/useCreateEditCommission';
import { useRolesDataContext } from '../providers/roles';
import useFetchRoles from '../hooks/useFetchRoles';

function AddEdit(props) {    
    const councilorId = props.data?.id;
    const [selected, setSelected] = useState({ eventKey: null, value: 'Член' });


    const [formValues, setFormValues] = useState({
        name: props.data?.firstname || '',
        lastname: props.data?.lastname || '',
        replies_within: props.data?.lastname || '',
        average_grade: props.data?.lastname || '',
        email: props.data?.email || '',
        number: props.data?.number || '',
        is_councilor: props.type == 'add' ? 1 : (props.data?.is_councilor || ''),
        role_id: props.data?.role_id || '',
        password: null,
        confirm_password: null,
        image: null,
    });
    const [file, setFile] = useState(props.data?.path || null);
    const [image, setImage] = useState(null);

    const { handleSave, loading, validations, handleValidations } = useCreateEditCouncilor(councilorId, props.onHide);

    function validate(fieldName) {
        return handleValidations().validate(fieldName)
    }

    function resetForm() {
        setFormValues({
            name: '',
            lastname: '',
            replies_within: '',
            average_grade: '',
            email: '',
            number: '',
            is_councilor: 1,
            role_id: '',
            password: null,
            confirm_password: null,
            image: null,
        })
        setFile(null)
        setImage(null)
        setSelected({ eventKey: null, value: 'Член' })
    }

    const onFormSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', formValues.name);
        formData.append('lastname', formValues.lastname);
        formData.append('email', formValues.email);
        formData.append('number', formValues.number);
        formData.append('is_councilor', Number(formValues.is_councilor));
        formData.append('role_id', formValues.role_id);
        if (props.type == 'add') {
            formData.append('password', formValues.password);
            formData.append('confirm_password', formValues.confirm_password);
        }
        if (image) {
            formData.append('path', image);
        }
        if (councilorId) {
            formData.append('id', councilorId);
        }
        handleSave(formData, resetForm);
    };

    const handleRemoveImage = () => {
        setFormValues(prevValues => ({
            ...prevValues,
            image: null,
        }))
        setFile(null)
        setImage("null")
    }

    const handleChange = e => {
        const { name, value, type, checked, files } = e.target;
        if (type === 'file') {
            setFile(URL.createObjectURL(files[0]));
            setImage(files[0]);
            setFormValues(prevValues => ({
                ...prevValues,
                [name]: files[0],
            }));
        } else {
            setFormValues(prevValues => ({
                ...prevValues,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };


    const handleSelect = (eventKey, event) => {
        setSelected({ eventKey: event.target.id, value: eventKey });
        setFormValues(prevValues => ({
            ...prevValues,
            role_id: event.target.id,
        }));
    };



    useEffect(() => {
        if (props.roles?.length > 0) {
            const matchedRole = props.roles?.find(role => role.id === formValues.role_id);
            if (matchedRole) {
                setSelected({ eventKey: matchedRole.id, value: matchedRole.name });
            } else {
                setSelected({ eventKey: null, value: 'Член' });
            }
        }
    }, [props.roles]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.type === 'edit' ? 'Редакция' : 'Добавяне'}
                </Modal.Title>
                <CloseButton onClick={props.onHide} variant='white' />
            </Modal.Header>
            <div className="sticky-container">
            <Modal.Body>
                <Form id='councilor-form' onSubmit={onFormSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label><strong>Име</strong></Form.Label>
                        <Form.Control
                            name='name'
                            type="text"
                            placeholder="Въведете име"                            
                            value={formValues.name}
                            onChange={handleChange}
                        />
                        {validate("name").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("name").message}</Form.Text>
                        ) : (
                            <></>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label><strong>Фамилия</strong></Form.Label>
                        <Form.Control
                            name='lastname'
                            type="text"
                            placeholder="Въведете фамилия"
                            value={formValues.lastname}
                            onChange={handleChange}
                        />
                        {validate("lastname").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("lastname").message}</Form.Text>
                        ) : (
                            <></>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label><strong>Е-поща</strong></Form.Label>
                        <Form.Control
                            name='email'
                            type="text"
                            placeholder="Въведете е-поща"
                            value={formValues.email}
                            onChange={handleChange}
                        />
                        {validate("email").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("email").message}</Form.Text>
                        ) : (
                            <></>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label><strong>Телефонен номер</strong></Form.Label>
                        <Form.Control
                            name='number'
                            type="text"
                            placeholder="Въведете номер"
                            value={formValues.number}
                            onChange={handleChange}
                        />
                        {validate("number").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("number").message}</Form.Text>
                        ) : (
                            <></>
                        )}
                    </Form.Group>
                    {props.type === 'add' ? <><Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label><strong>Парола</strong></Form.Label>
                        <Form.Control
                            name='password'
                            type="password"
                            placeholder="Въведете парола"
                            value={formValues.password}
                            onChange={handleChange}
                        />
                        {validate("password").message ? (
                            <>
                            <Form.Text style={{ color: 'red' }}>{validate("password").message}</Form.Text>
                            <br/>
                            </>
                        ) : (
                            <></>
                        )}
                        {validate("confirm_password").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("confirm_password").message}</Form.Text>
                        ) : (
                            <></>
                        )}
                    </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label><strong>Повторете парола</strong></Form.Label>
                            <Form.Control
                                name='confirm_password'
                                type="password"
                                placeholder="Повторете парола"
                                value={formValues.confirm_password}
                                onChange={handleChange}
                            />
                            {validate("confirm_password").message ? (
                                <Form.Text style={{ color: 'red' }}>{validate("confirm_password").message}</Form.Text>
                            ) : (
                                <></>
                            )}
                        </Form.Group></>
                        : <></>}
                    <Form.Group className="mb-3" controlId="formBasicImage">
                        <Form.Label><strong>Качете снимка</strong></Form.Label>
                        <br />
                        <input
                            id="fileInput"
                            name="path"
                            accept="image/*"
                            type="file"
                            onChange={handleChange}
                            style={{ display: 'none' }}
                        />
                        <label style={{ marginBottom: '10px' }} htmlFor="fileInput">
                            <Button as="span" variant="primary" className='app-button'>
                                Прикачете снимка
                            </Button>
                        </label>
                        <span style={{ marginLeft: '10px' }}>{formValues.image}</span>
                        {validate("path").message ? (
                            <Form.Text style={{ color: 'red' }}>{validate("path").message}</Form.Text>
                        ) : (
                            <></>
                        )}
                        <br />
                        {file && <div style={{ position: 'relative', display: 'inline-block' }}>
                            <Image style={{ maxHeight: '100px' }} src={file} thumbnail />
                            <CloseButton
                                onClick={handleRemoveImage}
                                style={{
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                    zIndex: 1,
                                    backgroundColor: 'red',
                                    color: 'white',
                                }}
                            />
                        </div>
                        }
                    </Form.Group>
                    {props.type == 'add' ? <></> : <Form.Group className="mb-3" controlId="formBasicIsCouncilor">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>Потребител</span>
                            <Form.Check
                                name='is_councilor'
                                type="switch"
                                checked={formValues.is_councilor}
                                onChange={handleChange}
                                style={{ marginLeft: '8px' }}
                            />
                            <span>Съветник</span>
                        </div>
                    </Form.Group>
                    }
                    {formValues.is_councilor ? <></> :
                        <Alert variant="warning">
                            <Alert.Heading>Внимание!</Alert.Heading>
                            <p>
                                Ако запазите, този съветник ще бъде преместен в секцията с потребители!
                            </p>
                        </Alert>}
                    <Form.Group>
                        <Form.Label><strong>Роля</strong></Form.Label>
                        {props.roles?.length > 0 ?
                            <DropdownButton onSelect={handleSelect} variant='secondary' id="dropdown-basic-button" title={selected?.value || "Роля"}>
                                {props.roles?.map((r) => (
                                    <Dropdown.Item value={r.name} id={r.id} eventKey={r.name} key={r.id}>{r.name}</Dropdown.Item>
                                ))}
                                <Dropdown.Item value={null} id={null} eventKey={"Член"} key={null}>Член</Dropdown.Item>
                            </DropdownButton>
                            : <></>}
                    </Form.Group>
                </Form>
            </Modal.Body>
            </div>
            <Modal.Footer className='sticky-div'>
                <Button variant="primary" className='app-button' form='councilor-form' type="submit" disabled={loading}>
                    {props.type === 'edit' ? 'Запази' : 'Добави'}
                </Button>
                <Button variant='secondary' onClick={props.onHide}>Затвори</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEdit;
