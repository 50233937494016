import { Alert, Button, CloseButton, Modal, Toast } from "react-bootstrap";
import Moment from "react-moment";
import "../../../assets/scss/app.scss"

function Messages(props) {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                dialogClassName="full-height-modal" // Custom class for full height
            >
                <Modal.Header className="modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Съобщения към тема "{props?.data?.name}"
                    </Modal.Title>
                    <CloseButton onClick={props.onHide} variant="white" />
                </Modal.Header>
                <Modal.Body className="modal-body-full-height"> {/* Scrollable body */}
                    {props?.data?.messages.length > 0 ? (
                        props?.data?.messages
                            .slice()
                            .reverse()
                            .map((c) => (
                                <div
                                    key={c.id}
                                    className={`d-flex mb-2 ${
                                        c.user.is_councilor
                                            ? "justify-content-end"
                                            : "justify-content-start"
                                    }`}
                                >
                                    <Toast style={{ width: "50%" }}>
                                        <Toast.Header closeButton={false}>
                                            <strong className="me-auto">
                                                {c.user.firstname ?? c.user.name}{" "}
                                                {c.user.lastname}
                                            </strong>
                                            <small>
                                                <Moment format="DD/MM/YYYY HH:mm">
                                                    {c.created_at}
                                                </Moment>
                                            </small>
                                        </Toast.Header>
                                        <Toast.Body>
                                            {c.image ? (
                                                <img
                                                    height="300px"
                                                    width="300px"
                                                    src={c.image}
                                                    alt="Message content"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            {c?.message}
                                        </Toast.Body>
                                    </Toast>
                                </div>
                            ))
                    ) : (
                        <Alert variant="warning">
                            <Alert.Heading>Няма намерени съобщения!</Alert.Heading>
                            <p>Към тази тема няма изпратени съобщения.</p>
                        </Alert>
                    )}
                </Modal.Body>
                <Modal.Footer className="modal-footer-sticky">
                    <Button variant="secondary" onClick={props.onHide}>
                        Затвори
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Messages;
