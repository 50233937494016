import { createContext, useContext, useState } from "react";
import useFetchCouncilors from "../hooks/useFetchCouncilors";
import useFetchTrashedCouncilors from "../hooks/useFetchTrashedCouncilors";

export const TrashedCouncilorsDataContext = createContext({})

const TrashedCouncilorsDataProvider = props => {
    const { getTrashedCouncilors, refetchTrashedData, getCurrentPageTrashed,setPageTrashed } = useFetchTrashedCouncilors()
    
    const exportedData = {       
        getTrashedCouncilors,
        refetchTrashedData,
        getCurrentPageTrashed,
        setPageTrashed
    }
    return <TrashedCouncilorsDataContext.Provider value={exportedData} {...props} />
}
export const useTrashedCouncilorsDataContext = () => useContext(TrashedCouncilorsDataContext)

export default TrashedCouncilorsDataProvider