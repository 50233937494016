function useConvertMinutes(totalMinutes)
{
    let responseTime = null;
    let days = totalMinutes/1440;
    let hours = (totalMinutes%1440)/60
    let minutes = (totalMinutes%1440)%60
    if(totalMinutes > 1440) {
        responseTime = Math.round(days)+" дни"
    }
    else if(totalMinutes < 1440 && totalMinutes > 60)
    {
        responseTime = (minutes == 0) ? Math.round(hours) +" часа" : Math.round(hours) +" часа и "+Math.round(minutes)+" минути";
    }
    else {
        responseTime = Math.round(minutes)+" минути";
    }

    return {
        days,
        hours,
        minutes,
        responseTime
    }

}
export default useConvertMinutes