import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENDPOINTS } from "../constants/endpoints";
import api from "../helpers/api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();
  const loginAction = async (data) => {
    try {
      const res = await api.post(ENDPOINTS.LOGIN, data);
      setUser(res.data.user);
      setToken(res.data.access_token);
      localStorage.setItem("site", res.data.access_token);
      navigate("/commissions");
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new Error("Unauthorized"); 
      }
      throw err; 
    }
  };
  

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("site");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};