import useFetch from "../../../hooks/useFetch"
import { ENDPOINTS } from "../../../constants/endpoints"
import { useUsersDataContext } from "../providers/users"

function useDeleteUser(userId = null) {
    const userData = useUsersDataContext()

    const {fetch,loading} = useFetch()

    function handleSave(data) {
        if (loading) return

        const endpoint = ENDPOINTS.USER.DELETE
        const method = "post"
        
        fetch({
            endpoint,
            method,
            data,
        })
        .then(() => {
            userData.refetchData(userData.getCurrentPage())
        })
    }

    return {
        loading,
        handleSave,
    
    }
}
export default useDeleteUser
